import { User } from '../App';
import { fetchJson } from '../Utils';
import { observable, action } from 'mobx';
import { globalState } from './GlobalState';

class ProfileState {
  @observable
  public isLoading: boolean = false;

  @observable
  public user: User = { 
    apiKey: '',
    isMetres: false,
    username: '',
    isPremium: false
  };

  @action
  async getProfile() {
    this.isLoading = true;
    this.user = await fetchJson<User>('/webapi/profile');
    this.isLoading = false;
  }

  @action 
  async saveProfile() {
    await fetchJson('/webapi/profile', { method: "POST", data: { isMetres: this.user.isMetres }});
    var globalAuth = globalState.auth;
    globalAuth.user.isMetres = this.user.isMetres;
    globalState.setAuth(globalAuth);
  }

  @action
  setProfile<T extends keyof User>(data: Pick<User, T>) {
    this.user = Object.assign(this.user, data);
  }
}

export const profileState = new ProfileState();