import * as React from 'react';
import { profileState } from '../Stores/ProfileState';
import { observer } from 'mobx-react';

@observer
export class Profile extends React.Component {
  constructor(props: any) {
    super(props);
    profileState.getProfile();
  }

  render() {
    const form = <form onSubmit={(e) => { e.preventDefault(); profileState.saveProfile(); }}>
      <div className="form-group row">
        <label htmlFor="apiKey" className="col-sm-2 col-form-label">Api Key</label>
        <div className="col-sm-10">
          <input type="text" readOnly className="form-control-plaintext" id="apiKey" value={profileState.user.apiKey} />
        </div>
      </div>
      <div className="form-group row">
        <div className="col-sm-2">Metres?</div>
        <div className="col-sm-10">
          <div className="form-check">
            <input className="form-check-input" type="checkbox" id="units" checked={profileState.user.isMetres} onChange={e => profileState.setProfile({ isMetres: e.target.checked })} />
            <label className="form-check-label" htmlFor="units"></label>
          </div>
        </div>
      </div>
      <div className="form-group row">
        <div className="col-sm-10">
          <button type="submit" className="btn btn-primary">Save</button>
        </div>
      </div>
    </form>;

    return (
      profileState.isLoading ? <p><em>Loading...</em></p> :
      <div className="card">
        <div className="card-header">
          <h3 className="card-title">Profile</h3>
        </div>
        <div className="card-body">
          {form}
        </div>
      </div>    
    );
  }
}
