import { navigate, Router } from 'takeme';
import { observable, action } from 'mobx';
import { sessionState } from './SessionState'

// URLs
export const links = {
  home: () => '/',
  signin: () => '/signin',
  profile: () => '/profile',
  help: () => '/help',
  sessions: () => '/sessions',
  sessionShots: (id: string, shotId?: string) => {
    let shotSeg = shotId ? `(/${shotId})` : '';
    return `/sessions/${id}${shotSeg}`;
  },
}

// State 
export enum Route {
  home = 'home',
  signin = 'signin',
  profile = 'profile',
  help = 'help',
  sessions = 'sessions',
  sessionShots = 'sessionShots'
}

var routeLinkMappings: { [route in Route]?: Route } = {};
routeLinkMappings[Route.sessionShots] = Route.sessions;

export class RouteState {
  @observable route: Route = Route.home;
  @observable linkRoute: Route = Route.home;
  @observable showMenu: boolean = false;

  @action setRoute(route: Route) {
    this.route = route;
    this.linkRoute = (routeLinkMappings[route] ? routeLinkMappings[route] : route) as Route;
    this.showMenu = false;
  }

  @observable sessionId: string = '';
  @observable sessionShotId: string | undefined;
  @action async setSessionId(sessionId: string, shotId?: string) {
    this.sessionId = sessionId;
    this.sessionShotId = shotId;
    var sessionShotsState = sessionState.get(sessionId);
    await sessionShotsState.routed(shotId);
  }

  @action toggleMenu() {
    this.showMenu = !this.showMenu;
  }
}

export const routeState = new RouteState();

export const router = new Router([
  {
    $: links.signin(),
    enter: () => routeState.setRoute(Route.signin)
  },
  {
    $: links.sessions(),
    enter: () => routeState.setRoute(Route.sessions)
  },
  {
    $: links.profile(),
    enter: () => routeState.setRoute(Route.profile)
  },
  {
    $: links.help(),
    enter: () => routeState.setRoute(Route.help)
  },
  {
    $: links.sessionShots(':sessionId', ':shotId'),
    beforeEnter: async ({ params: { sessionId, shotId } }) => {
      routeState.setRoute(Route.sessionShots); 
      await routeState.setSessionId(sessionId, shotId);
    },
    enter: ({ params: { sessionId } }) => { },
  },
  { $: '*', enter: () => routeState.setRoute(Route.home) },
]).enableHtml5Routing().init();
