import * as React from 'react';
import { observer } from "mobx-react";
import * as signalR from '@aspnet/signalr';
import './SessionShots.css';
import classNames from 'classnames';
import { InlineEdit } from './InlineEdit';
import { GroupedBy, Shot, ClubData, BallData, SessionShotsState, SpinNumbers } from '../Stores/SessionShotState';
import { sessionState } from '../Stores/SessionState';
import { globalState } from '../Stores/GlobalState';
import { routeState } from '../Stores/RouteState';
import { formatDate, DateType, avg, group, orderedGroup, arrayMath } from '../Utils';
import { ShotSettings } from './ShotSettings';
import { ShotVisual } from './ShotVisual';
import math from 'mathjs';
import { BallFlight } from './BallFlight';

const formatNum = (num: number, precision?: number) => {
  if (num === null)
    return '';
  var result = math.format(num, { notation: 'fixed', precision: precision === undefined ? 1 : precision });
  return result;
}

@observer
export class SessionShots extends React.Component {
  connection: signalR.HubConnection;
  store: SessionShotsState;

  constructor(props: any) {
    super(props);

    this.store = sessionState.get(routeState.sessionId);

    this.connection = new signalR.HubConnectionBuilder()
      .withUrl("https://schogolf.net/api/liveshot")
      //.configureLogging(signalR.LogLevel.Information)
      .build();

    this.connection.on("Shot", (shot) => {
      this.store.onShot(shot);
    });

    this.start().then(x => {
      this.connection.invoke('Register', routeState.sessionId);
      this.store.setLiveConnection();
    });

    this.connection.onclose(async () => {
      await this.start();
    });
  }

  async start() {
    try {
      await this.connection.start();
    } catch (err) {
      console.log(err);
      setTimeout(() => this.start(), 5000);
    }
  }

  distinct<T>(list: T[]) {
    function onlyUnique(value: T, index: number, self: any) {
      return self.indexOf(value) === index;
    }
    return list.filter(onlyUnique);
  }

  componentWillUnmount() {
    this.connection.stop();
  }

  renderShots(shots: Shot[]) {

    let groupedShots: JSX.Element[];
    if (this.store.groupedByClub === GroupedBy.Grouped || this.store.groupedByClub === GroupedBy.ByClub) {
      let filteredShots = this.store.showClubData ? shots.filter(x => x.shotData.clubData.clubSpeed > 0) : shots;
      let groupSeq = this.store.groupedByClub === GroupedBy.ByClub ? group(filteredShots, x => x.club || '') : orderedGroup(filteredShots, x => x.club);
      groupedShots = groupSeq.map((x, j) => {
        let selectedShotsInGroup = x.values.filter(x => Object.keys(this.store.selectedShots).findIndex(z => z === x.id && this.store.selectedShots[z]) >= 0).map(x => x.id);
        return (
          <React.Fragment key={x.key + j}>
            {x.values.map((y, i) => this.renderShot(y, i, x.values, selectedShotsInGroup.length))}
            {this.store.showClubData
              ? <ClubAvgs key={x.key} shots={x.values} selectedShotsInGroup={selectedShotsInGroup} />
              : <BallAvgs key={x.key} shots={x.values} selectedShotsInGroup={selectedShotsInGroup} displayInMetres={this.store.displayInMetres} spinNumbers={this.store.settings.spinNumbers} />}
          </React.Fragment>
        );
      });
    }
    else if (this.store.groupedByClub === GroupedBy.Summary) {
      let filteredShots = this.store.showClubData ? shots.filter(x => x.shotData.clubData.clubSpeed > 0) : shots;
      let groupSeq = group(filteredShots, x => x.club || '');
      groupedShots = groupSeq.map((x, j) => {
        let selectedShotsInGroup = x.values.filter(x => Object.keys(this.store.selectedShots).findIndex(z => z === x.id && this.store.selectedShots[z]) >= 0).map(x => x.id);
        return (
          <React.Fragment key={x.key}>
            {this.store.showClubData
              ? <ClubAvgs shots={x.values} selectedShotsInGroup={selectedShotsInGroup} isSummary={true} />
              : <BallAvgs shots={x.values} selectedShotsInGroup={selectedShotsInGroup} isSummary={true} displayInMetres={this.store.displayInMetres} spinNumbers={this.store.settings.spinNumbers} />
            }
            <tr>
              <td colSpan={this.store.showClubData ? 16 : 14} style={{ backgroundColor: '#12121d' }}>
                <BallFlight
                  currentHoverShotId={this.store.currentHoverShot}
                  animateLast={this.store.animateLast}
                  ballFlightData={this.store.summaryTracerPoints.filter(z => z.club == x.key)}
                  carryDispersion={this.store.carryDispersion(this.store.summaryTracerPoints.filter(z => z.club == x.key))}
                  clubColors={this.store.clubColors}
                  displayDispersionCircles={this.store.settings.showDispersionCircles}
                  displayInMetres={this.store.displayInMetres} />
              </td>
            </tr>
          </React.Fragment>
        );
      });
    }
    else {
      let selectedShots = Object.keys(this.store.selectedShots).filter(x => this.store.selectedShots[x]).map(x => this.store.shots[x].id);
      groupedShots = shots
        .map((shot, i) => this.renderShot(shot, i, shots, selectedShots.length))
        .concat(this.store.showClubData
          ? <ClubAvgs key={'___summary'} shots={shots} selectedShotsInGroup={selectedShots} />
          : <BallAvgs key={'___summary'} shots={shots} selectedShotsInGroup={selectedShots} displayInMetres={this.store.displayInMetres} spinNumbers={this.store.settings.spinNumbers} />
        );
    }

    return (
      <div className="table-responsive-lg">
        <table id="sessionShots" className="table table-condensed table-hover">
          <thead>
            <tr>
              <th colSpan={2}></th>
              <th style={{width: 0,padding:0}}></th>
              <th className="text-center">Shot{this.store.groupedByClub == GroupedBy.Summary ? 's' : ''}</th>
              <th className="text-center">Club</th>              
              {this.store.groupedByClub != GroupedBy.Summary && <th className="text-right">Date</th>}
              {this.store.showClubData ? this.renderClubDataSpecificHeaders() : this.renderBallDataSpecificHeaders()}
            </tr>
          </thead>
          <tbody>
            {groupedShots}
          </tbody>
        </table>
      </div>
    );
  }

  renderBallDataSpecificHeaders() {
    var headers = (
      <React.Fragment>
        <th className="text-right">Ball Speed</th>
        <th className="text-right">Launch Ang</th>
        <th className="text-right">Launch Dir</th>
        <th className="text-right">{this.store.settings.spinNumbers == SpinNumbers.SideBack ? "Back Spin" : "Total Spin"}</th>
        <th className="text-right">{this.store.settings.spinNumbers == SpinNumbers.SideBack ? "Side Spin" : "Spin Axis"}</th>
        <th className="text-right">Offline {this.store.displayInMetres ? '(m)' : '(y)'}</th>
        <th className="text-right">Height {this.store.displayInMetres ? '(m)' : '(y)'}</th>
        <th className="text-right">Carry {this.store.displayInMetres ? '(m)' : '(y)'}</th>
        <th className="text-right">&nbsp;</th>
      </React.Fragment>
    );
    return headers;
  }

  renderBallDataSpecificRow(shot: Shot) {

    let copy = () => {
      const el = document.createElement('textarea');
      el.value = `https://schogolf.net/sessions/${this.store.sessionId}/${shot.id}`;
      document.body.appendChild(el);
      el.select();
      document.execCommand('copy');
      document.body.removeChild(el);
    };

    let premium = globalState.auth.user.isPremium;
    let awesome = (win: boolean) => classNames({
      'flashit training-good': win
    });

    const isSideBack = this.store.settings.spinNumbers == SpinNumbers.SideBack;
    let backTotalSpin = isSideBack ? formatNum(shot.shotData.ballData.backSpin, 0) : formatNum(shot.shotData.ballData.totalSpin, 0);
    let sideAxisSpin = isSideBack ? formatNum(shot.shotData.ballData.sideSpin, 0) : formatNum(shot.shotData.ballData.spinAxis, 1);
    let degSymbol = isSideBack ? null : <span>&deg;</span>

    let rows = (
      <React.Fragment>
        <td className="text-right">{formatNum(shot.shotData.ballData.ballSpeed)}</td>
        <td className="text-right">{formatNum(shot.shotData.ballData.launchAngle)}&deg;</td>
        <td className="text-right">{formatNum(shot.shotData.ballData.launchDirection)}&deg;</td>
        <td className="text-right">{backTotalSpin}</td>
        <td className="text-right">{sideAxisSpin}{degSymbol}</td>
        <td className="text-right">
          <span className={awesome(premium && Math.abs(shot.shotData.ballData.offline) < 0.05)}>
            {formatNum(this.store.displayInMetres ? shot.shotData.ballData.offlineMts : shot.shotData.ballData.offline)}
          </span>
        </td>
        <td className="text-right">{formatNum(this.store.displayInMetres ? shot.shotData.ballData.peakHeightMts : shot.shotData.ballData.peakHeight)}</td>
        <td className="text-right">{formatNum(this.store.displayInMetres ? shot.shotData.ballData.carryMts : shot.shotData.ballData.carry)}</td>
        <td className="text-right text-nowrap">
          <div className="btn-group">
            {shot.shotData.clubData.clubSpeed > 0
              && <button type="button" className="btn btn-success btn-xs" onClick={() => this.store.toggleClubData(shot.id)}>CLUB</button>}
            <button className="btn btn-secondary btn-xs dropdown-toggle" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"><span className="sr-only">Toggle Dropdown</span></button>
            <div className="dropdown-menu dropdown-menu-right" aria-labelledby="dropdownMenuButton">
              {globalState.auth.user.isPremium && <span className="dropdown-item">
                Rank
                <a href="javascript:;" style={{ color: 'var(--success)', marginLeft: 10 }} onClick={() => this.store.rankShot(shot.id, 1)}>&#11044;</a>
                <a href="javascript:;" style={{ color: 'var(--warning)', marginLeft: 10 }} onClick={() => this.store.rankShot(shot.id, 2)}>&#11044;</a>
                <a href="javascript:;" style={{ color: 'var(--danger)', marginLeft: 10 }} onClick={() => this.store.rankShot(shot.id, 3)}>&#11044;</a>
                <a href="javascript:;" style={{ color: '#ccc', marginLeft: 10 }} onClick={() => this.store.rankShot(shot.id, 0)}>&#11044;</a>
              </span>}
              <a className="dropdown-item" href="javascript:;" onClick={() => copy()}>Copy Link</a>
              {this.store.isOwnSession && <a className="dropdown-item" href="javascript:;" onClick={() => this.store.archiveShot(shot.id)}>{shot.archived ? 'Restore' : 'Archive'}</a>}
            </div>
          </div>
        </td>
      </React.Fragment>
    );

    return rows;
  }



  setSelected(shots: Shot[], selected?: boolean) {
    this.store.setSelected(shots, selected);
  }

  toggleGroupSelection(shotsInGroup: Shot[], selectedShotsInGroup: number) {
    if (shotsInGroup.length / 2 >= selectedShotsInGroup) {
      this.setSelected(shotsInGroup, true);
    }
    else {
      this.setSelected(shotsInGroup, false);
    }
  }

  //https://stackoverflow.com/a/44038181
  renderShot(shot: Shot, i: number, shotsInGroup: Shot[], selectedShotsInGroup: number) {
    var isMisread = shot.shotData.ballData.ballSpeed === 0;
    var displayExtra = (!isMisread
      && (shot.shotData.clubData.clubSpeed > 0))
      && this.store.openCloseStates[shot.id]
      && !this.store.showClubData;

    var openCloseStatesInGroup = this.store.showClubData ? 0 : Object.keys(this.store.openCloseStates).filter(x => this.store.openCloseStates[x] && shotsInGroup.map(z => z.id).findIndex(z => z === x) >= 0).length;
    var classes = classNames({
      'misread': isMisread,
      'notSelected': selectedShotsInGroup > 0 && !this.store.selectedShots[shot.id]
    });

    var styles = {};
    if (this.store.selectedShots[shot.id]) {
      styles['borderLeft'] = `2px solid ${this.store.clubColors[shot.club]}`
    }

    var ratingMap = {
      1: 'var(--success)',
      2: 'var(--warning)',
      3: 'var(--danger)'
    };

    return <React.Fragment key={shot.id}>
      <tr className={classes} onMouseEnter={() => this.store.setHoverShot(shot.id)} onMouseLeave={() => this.store.setUnHoverShot()}>
        {i === 0 && <td className='group-selection' title="select all in group" rowSpan={shotsInGroup.length + openCloseStatesInGroup} onClick={() => this.toggleGroupSelection(shotsInGroup, selectedShotsInGroup)}></td>}
        <td className="text-center" style={{ paddingLeft: "10px" }}>
          {!shot.archived &&
            <input type="checkbox" className="shotSelectCheck" checked={this.store.selectedShots[shot.id] || false} onChange={() => this.setSelected([shot])} />
          }
        </td>
        <td style={{padding:0}}>{shot.rating > 0 && <span style={{ color: ratingMap[shot.rating] }}>&#9679;</span>}</td>
        <td className="text-center">{shotsInGroup.length - i}</td>
        <td className="text-center" style={styles}>
          <InlineEdit isDisabled={!this.store.isOwnSession} title="Click to edit" placeholder={'-'} text={shot.club === null ? '' : shot.club} paramName="club" change={(d) => this.updateShot(shot.id, d['club'])} style={{ display: 'inline-block', width: '70px', minHeight: '1em' }} />
        </td>
        <td className="text-right text-nowrap" title={formatDate(shot.date, DateType.Full)}>{formatDate(shot.date, DateType.Time)}</td>
        {this.store.showClubData ? this.renderClubDataSpecificRow(shot) : this.renderBallDataSpecificRow(shot)}
      </tr>
      {displayExtra && this.renderInlineClubData(shot)}
    </React.Fragment>;
  }

  updateShot(shotId: string, value: string) {
    this.store.updateShot(shotId, value);
  }

  renderClubDataSpecificRow(shot: Shot) {
    var hasData = shot.shotData.clubData.clubSpeed;
    var rows = (
      <React.Fragment>
        <td className="text-right">{formatNum(shot.shotData.clubData.clubSpeed)}</td>
        <td className="text-right">{hasData && formatNum(shot.shotData.smashFactor, 2)}</td>
        <td className="text-right">{formatNum(shot.shotData.clubData.angleOfAttack)}{shot.shotData.clubData.angleOfAttack ? <span>&deg;</span> : ''}</td>
        <td className="text-right">{formatNum(shot.shotData.clubData.clubPath)}{shot.shotData.clubData.clubPath ? <span>&deg;</span> : ''}</td>
        <td className="text-right">{hasData && formatNum(shot.shotData.clubData.faceToPath)}{shot.shotData.clubData.faceToPath ? <span>&deg;</span> : ''}</td>
        <td className="text-right">{hasData && formatNum(shot.shotData.clubData.faceToTarget)}{shot.shotData.clubData.faceToTarget ? <span>&deg;</span> : ''}</td>
        <td className="text-right">{formatNum(shot.shotData.clubData.lie)}{shot.shotData.clubData.lie ? <span>&deg;</span> : ''}</td>
        <td className="text-right">{formatNum(shot.shotData.clubData.loft)}{shot.shotData.clubData.loft ? <span>&deg;</span> : ''}</td>
        <td className="text-right">{formatNum(shot.shotData.clubData.closingRate, 0)}</td>
        <td className="text-right">{formatNum(shot.shotData.clubData.horizontalImpact)}</td>
        <td className="text-right">{formatNum(shot.shotData.clubData.verticalImpact)}</td>
      </React.Fragment>
    );
    return rows;
  }

  renderClubDataRow(shot: Shot) {
    return (
      <tr>
        {this.renderClubDataSpecificRow(shot)}
      </tr>
    );
  }

  renderClubDataSpecificHeaders() {
    var headers = (
      <React.Fragment>
        <th className="text-right">Club Speed</th>
        <th className="text-right">Smash Factor</th>
        <th className="text-right">Angle of Attack</th>
        <th className="text-right">Club Path</th>
        <th className="text-right">Face to Path</th>
        <th className="text-right">Face to Target</th>
        <th className="text-right">Club Lie</th>
        <th className="text-right">Dynamic Loft</th>
        <th className="text-right">Closing Rate</th>
        <th className="text-right">H Impact</th>
        <th className="text-right">V Impact</th>
      </React.Fragment>
    );
    return headers;
  }

  renderClubDataHeaders() {
    return (
      <tr>
        {this.renderClubDataSpecificHeaders()}
      </tr>
    );
  }

  renderInlineClubData(shot: Shot) {
    return <tr className="sub">
      <td colSpan={14} style={{ padding: 0, paddingBottom: 3 }}>
        <div style={{ border: '1px dotted #bdbdbd', borderRadius: '3px' }}>
          <table className='table table-condensed' style={{ marginBottom: 0, background: "inherit" }}>
            <thead>
              {this.renderClubDataHeaders()}
            </thead>
            <tbody>
              {this.renderClubDataRow(shot)}
            </tbody>
          </table>
        </div>
      </td>
    </tr>;
  }

  toggleGroupBy(grouped: GroupedBy) {
    this.store.toggleGroupBy(grouped);
  }

  toggleDataType(clubData: boolean) {
    this.store.toggleDataType(clubData);
  }

  renderFilters() {
    return (
      <div className="card card-nested">
        <div className="card-body">
          <div className="form-check">
            <input className="form-check-input" type="checkbox" value="" id="showArchived" checked={this.store.filterSettings.showArchived} onChange={(e) => this.store.setFilters({ showArchived: e.target.checked })} />
            <label className="form-check-label" htmlFor="showArchived">
              Show Archived Shots
            </label>
          </div>
        </div>
      </div>
    );
  }

  render() {
    let contents = this.store.loading
      ? <p><em>Loading...</em></p>
      : this.renderShots(this.store.shotsInReverseOrder.map(x => this.store.shots[x]));

    let labelClasses = (showClubData: boolean) => classNames({
      'btn btn-primary': true,
      'active': showClubData === this.store.showClubData
    });

    let labelClassesGroups = (grouped: GroupedBy) => classNames({
      'btn btn-primary': true,
      'active': grouped === this.store.groupedByClub
    });

    let tracerClasses = () => classNames({
      'btn btn-primary': true,
      'active': globalState.isTracerActive
    });

    let settingsClasses = () => classNames({
      'btn btn-primary': true,
      'active': this.store.isSettingsOpen
    });

    let visualClasses = () => classNames({
      'btn btn-primary': true,
      'active': this.store.isVisualOpen
    });

    let filterClasses = () => classNames({
      'btn btn-primary': true,
      'active': this.store.isFiltersOpen
    });

    var name = (this.store.sessionName && (this.store.sessionName + ' - ' + this.store.sessionId)) || this.store.sessionId;

    return (
      <div className="sessionShots">
        <ShotSettings sessionId={this.store.sessionId} />
        <ShotVisual sessionId={this.store.sessionId} />
        <div className="card">
          <div className="card-header">
            <span style={{ "float": "right", "fontSize": "14px", fontWeight: 500 }} className={`badge badge-${this.store.connectedState.label}`}>{this.store.connectedState.text}</span>
            <h3 className="card-title">
              Shots for Session: {name}
            </h3>
            <div className="header-buttons">
              <div className="btn-group btn-group-toggle" data-toggle="buttons" style={{ marginRight: 7 }}>
                <label className={labelClasses(false)} onClick={() => this.toggleDataType(false)}>
                  <input type="radio" name="options" id="option1" autoComplete="off" /> Ball
                </label>
                <label className={labelClasses(true)} onClick={() => this.toggleDataType(true)}>
                  <input type="radio" name="options" id="option2" autoComplete="off" /> Club
                </label>
              </div>
              <div className="btn-group btn-group-toggle" data-toggle="buttons" style={{ marginRight: 7 }}>
                <label className={labelClassesGroups(GroupedBy.Default)} onClick={() => this.toggleGroupBy(GroupedBy.Default)}>
                  <input type="radio" name="options" id="option1" autoComplete="off" /> Default
                </label>
                <label className={labelClassesGroups(GroupedBy.Grouped)} onClick={() => this.toggleGroupBy(GroupedBy.Grouped)}>
                  <input type="radio" name="options" id="option2" autoComplete="off" /> Grouped
                </label>
                <label className={labelClassesGroups(GroupedBy.ByClub)} onClick={() => this.toggleGroupBy(GroupedBy.ByClub)}>
                  <input type="radio" name="options" id="option3" autoComplete="off" /> By Club
                </label>
                {globalState.auth.user.isPremium &&
                  <label className={labelClassesGroups(GroupedBy.Summary)} onClick={() => this.toggleGroupBy(GroupedBy.Summary)}>
                    <input type="radio" name="options" id="option4" autoComplete="off" /> Summary
                  </label>
                }
              </div>
              <div className="btn-group" style={{ marginRight: 7 }}>
                <button type="button" className={tracerClasses()} data-toggle="button" aria-pressed="false" onClick={() => globalState.toggleTracer()}>
                  Tracer
                </button>
                <button type="button" className={visualClasses()} data-toggle="button" aria-pressed="false" onClick={() => this.store.toggleVisual()}>
                  Visual
                </button>
              </div>
              <div className="btn-group">
                <button type="button" className={filterClasses()} data-toggle="button" aria-pressed="false" onClick={() => this.store.toggleFilters()}>
                  Filters
                </button>
                <button type="button" className={settingsClasses()} data-toggle="button" aria-pressed="false" onClick={() => this.store.toggleSettings()}>
                  Settings
                </button>
              </div>
            </div>

          </div>
          <div className="card-body">
            <div className="sessionShotsContent">
              {this.store.isFiltersOpen && this.renderFilters()}
              {contents}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

interface BallAvgsData {
  shots: Shot[],
  selectedShotsInGroup: string[],
  displayInMetres: boolean,
  spinNumbers: SpinNumbers,
  isSummary?: boolean
}

function BallAvgs({ shots, selectedShotsInGroup, displayInMetres, spinNumbers, isSummary }: BallAvgsData) {

  let filteredShots = shots;
  if (selectedShotsInGroup.length > 0) {
    filteredShots = filteredShots.filter(x => selectedShotsInGroup.findIndex(z => z === x.id) >= 0);
  }
  let ballData = filteredShots.map(x => x.shotData.ballData).filter(x => x.ballSpeed > 0);
  let roundToTwo = (num: number) => +(Math.round(num + "e+2" as any) + "e-2");
  let avgs: BallData = {
    carry: roundToTwo(avg(ballData.map(x => x.carry))),
    carryMts: roundToTwo(avg(ballData.map(x => x.carryMts))),
    ballSpeed: roundToTwo(avg(ballData.map(x => x.ballSpeed))),
    launchAngle: roundToTwo(avg(ballData.map(x => x.launchAngle))),
    launchDirection: roundToTwo(avg(ballData.map(x => x.launchDirection))),
    backSpin: roundToTwo(avg(ballData.map(x => x.backSpin))),
    sideSpin: roundToTwo(avg(ballData.map(x => x.sideSpin))),
    peakHeight: roundToTwo(avg(ballData.map(x => x.peakHeight))),
    peakHeightMts: roundToTwo(avg(ballData.map(x => x.peakHeightMts))),
    offline: roundToTwo(avg(ballData.map(x => x.offline))),
    offlineMts: roundToTwo(avg(ballData.map(x => x.offlineMts))),
    spinAxis: roundToTwo(avg(ballData.map(x => x.spinAxis))),
    totalSpin: roundToTwo(avg(ballData.map(x => x.totalSpin)))
  };

  const subFontWeight = { fontWeight: 100 };

  //offline
  const leftOffline = ballData.filter(x => x.offline < 0);
  const rightOffline = ballData.filter(x => x.offline > 0);
  const offlineL = <React.Fragment>{formatNum(-roundToTwo(avg(leftOffline.map(x => x.offline))))}L <span style={subFontWeight}>({leftOffline.length})</span></React.Fragment>;
  const offlineR = <React.Fragment>{formatNum(roundToTwo(avg(rightOffline.map(x => x.offline))))}R <span style={subFontWeight}>({rightOffline.length})</span></React.Fragment>;
  const offlineLMts = <React.Fragment>{formatNum(-roundToTwo(avg(leftOffline.map(x => x.offlineMts))))}L <span style={subFontWeight}>({leftOffline.length})</span></React.Fragment>;
  const offlineRMts = <React.Fragment>{formatNum(roundToTwo(avg(rightOffline.map(x => x.offlineMts))))}R <span style={subFontWeight}>({rightOffline.length})</span></React.Fragment>;

  //height
  const heightRange = <span style={subFontWeight}>{formatNum(ballData.length > 0 ? Math.min(...ballData.map(x => x.peakHeight)) : 0)} - {formatNum(Math.max(...[0].concat(ballData.map(x => x.peakHeight))))}</span>;
  const heightRangeMts = <span style={subFontWeight}>{formatNum(ballData.length > 0 ? Math.min(...ballData.map(x => x.peakHeightMts)) : 0)} - {formatNum(Math.max(...[0].concat(ballData.map(x => x.peakHeightMts))))}</span>;

  //carry
  const carryRange = <span style={subFontWeight}>{formatNum(ballData.length > 0 ? Math.min(...ballData.map(x => x.carry)) : 0)} - {formatNum(Math.max(...[0].concat(ballData.map(x => x.carry))))}</span>;
  const carryRangeMts = <span style={subFontWeight}>{formatNum(ballData.length > 0 ? Math.min(...ballData.map(x => x.carryMts)) : 0)} - {formatNum(Math.max(...[0].concat(ballData.map(x => x.carryMts))))}</span>;

  const offlineDisp = globalState.auth.user.isPremium
    ? <span>
      {displayInMetres ? offlineLMts : offlineL}
      <br />
      {displayInMetres ? offlineRMts : offlineR}
    </span>
    : formatNum(displayInMetres ? avgs.offlineMts : avgs.offline);

  const heightDisp = globalState.auth.user.isPremium
    ? <span>{formatNum(displayInMetres ? avgs.peakHeightMts : avgs.peakHeight)}
      <br />
      {displayInMetres ? heightRangeMts : heightRange}
    </span>
    : formatNum(displayInMetres ? avgs.peakHeightMts : avgs.peakHeight)

  const carryDisp = globalState.auth.user.isPremium
    ? <span>
      {formatNum(displayInMetres ? avgs.carryMts : avgs.carry)}
      <br />
      {displayInMetres ? carryRangeMts : carryRange}
    </span>
    : formatNum(displayInMetres ? avgs.carryMts : avgs.carry)

  const isSideBack = spinNumbers == SpinNumbers.SideBack;
  const backTotalAvgSpin = isSideBack ? formatNum(avgs.backSpin, 0) : formatNum(avgs.totalSpin, 0);
  const sideSpinAxisAvg = isSideBack ? formatNum(avgs.sideSpin, 0) : formatNum(avgs.spinAxis, 1);
  const degSymbol = isSideBack ? null : <span>&deg;</span>

  const shotClub = isSummary
    ? <React.Fragment>
      <th colSpan={3} className="text-left"></th>
      <th className="text-center">{shots.length}</th>
      <th className="text-center">{shots[0].club}</th>
    </React.Fragment>
    : <React.Fragment><th colSpan={6} className="text-left"></th></React.Fragment>

  const summaryClasses = classNames({
    'avgs': true,
    'summary': isSummary
  });

  return (
    <tr className={summaryClasses}>
      {shotClub}
      <th className="text-right" title="Ball Speed">{formatNum(avgs.ballSpeed)}</th>
      <th className="text-right" title="Launch Angle">{formatNum(avgs.launchAngle)}&deg;</th>
      <th className="text-right" title="Launch Direction">{formatNum(avgs.launchDirection)}&deg;</th>
      <th className="text-right" title={isSideBack ? "Back Spin" : "Total Spin"}>{backTotalAvgSpin}</th>
      <th className="text-right" title={isSideBack ? "Side Spin" : "Spin Axis"}>{sideSpinAxisAvg}{degSymbol}</th>
      <th className="text-right" title={`Offline ${displayInMetres ? '(m)' : '(y)'}`}>
        {offlineDisp}
      </th>
      <th className="text-right" title={`Peak Height ${displayInMetres ? '(m)' : '(y)'}`}>
        {heightDisp}
      </th>
      <th className="text-right" title={`Carry ${displayInMetres ? '(m)' : '(y)'}`}>
        {carryDisp}
      </th>
      <th></th>
    </tr>
  );
}

interface ClubAvgsData {
  shots: Shot[],
  selectedShotsInGroup: string[],
  isSummary?: boolean
}

function ClubAvgs({ shots, selectedShotsInGroup, isSummary }: ClubAvgsData) {
  let filteredShots = shots;
  if (selectedShotsInGroup.length > 0) {
    filteredShots = filteredShots.filter(x => selectedShotsInGroup.findIndex(z => z === x.id) >= 0);
  }
  let clubData = filteredShots.filter(x => x.shotData.ballData.ballSpeed > 0 && x.shotData.clubData.clubSpeed).map(x => x.shotData.clubData);
  let smash = filteredShots.map(x => x.shotData.smashFactor);
  let calcAvg = <T extends {}>(list: T[], selector: (item: T) => number) => avg(list.map(x => selector(x)));
  let roundToTwo = (num: number) => math.round(num, 2) as number;
  let avgs: ClubData = {
    clubSpeed: roundToTwo(calcAvg(clubData, x => x.clubSpeed)),
    angleOfAttack: roundToTwo(calcAvg(clubData, x => x.angleOfAttack)),
    loft: roundToTwo(calcAvg(clubData, x => x.loft)),
    clubPath: roundToTwo(calcAvg(clubData, x => x.clubPath)),
    faceToPath: roundToTwo(calcAvg(clubData, x => x.faceToPath)),
    faceToTarget: roundToTwo(calcAvg(clubData, x => x.faceToTarget)),
    closingRate: roundToTwo(calcAvg(clubData, x => x.closingRate)),
    lie: roundToTwo(calcAvg(clubData, x => x.lie)),
    horizontalImpact: roundToTwo(calcAvg(clubData, x => x.horizontalImpact)),
    verticalImpact: roundToTwo(calcAvg(clubData, x => x.verticalImpact)),
  };
  let smashFactor = roundToTwo(calcAvg(smash, x => x));

  const shotClub = isSummary
    ? <React.Fragment>
      <th colSpan={3} className="text-left"></th>
      <th className="text-center">{shots.length}</th>
      <th className="text-center">{shots[0].club}</th>
    </React.Fragment>
    : <React.Fragment><th colSpan={6} className="text-left"></th></React.Fragment>

  const summaryClasses = classNames({
    'avgs': true,
    'summary': isSummary
  });

  return (
    <tr className={summaryClasses}>
      {shotClub}
      <th className="text-right" title="Club Speed">{formatNum(avgs.clubSpeed)}</th>
      <th className="text-right" title="Smash Factor">{formatNum(smashFactor, 2)}</th>
      <th className="text-right" title="Angle of Attack">{formatNum(avgs.angleOfAttack)}&deg;</th>
      <th className="text-right" title="Club Path">{formatNum(avgs.clubPath)}&deg;</th>
      <th className="text-right" title="Face to Path">{formatNum(avgs.faceToPath)}&deg;</th>
      <th className="text-right" title="Face to Target">{formatNum(avgs.faceToTarget)}&deg;</th>
      <th className="text-right" title="Club Lie">{formatNum(avgs.lie)}{avgs.lie ? <span>&deg;</span> : ''}</th>
      <th className="text-right" title="Dynamic Loft">{formatNum(avgs.loft)}{avgs.loft ? <span>&deg;</span> : ''}</th>
      <th className="text-right" title="Closing Rate">{formatNum(avgs.closingRate, 0) || ''}</th>
      <th className="text-right" title="Horizontal Impact">{formatNum(avgs.horizontalImpact) || ''}</th>
      <th className="text-right" title="Vertical Impact">{formatNum(avgs.verticalImpact) || ''}</th>
    </tr>
  );
}