import * as React from 'react';
import * as ReactDOM from 'react-dom';

function selectInputText(element: HTMLInputElement) {
  element.setSelectionRange(0, element.value.length);
}

interface Props {
  text: string,
  paramName: string,
  change: (data: { [id: string]: string }) => void;
  placeholder?: string,
  className?: string,
  activeClassName?: string,
  minLength?: number,
  maxLength?: number,
  validate?: (text: string) => boolean,
  style?: object,
  editingElement?: string,
  staticElement?: string,
  tabIndex?: number,
  isDisabled?: boolean,
  editing?: boolean,
  stopPropagation?: boolean,
  title?: string
}

interface State {
  editing?: boolean;
  text: string;
  minLength?: number;
  maxLength?: number;
}

class InlineEdit extends React.Component<Props, State> {

  static defaultProps = {
    minLength: 1,
    maxLength: 256,
    editingElement: 'input',
    staticElement: 'span',
    tabIndex: 0,
    isDisabled: false,
    editing: false
  };

  constructor(props: any) {
    super(props);
    this.state = {
      editing: this.props.editing,
      text: this.props.text,
      minLength: this.props.minLength,
      maxLength: this.props.maxLength
    };
  }

  componentWillMount() {
    this.isInputValid = this.props.validate || this.isInputValid;
  }

  componentWillReceiveProps(nextProps: Props) {
    const isTextChanged = (nextProps.text !== this.props.text);
    const isEditingChanged = (nextProps.editing !== this.props.editing);
    let nextState: Partial<State> = {};
    if (isTextChanged) {
      nextState.text = nextProps.text;
    }
    if (isEditingChanged) {
      nextState.editing = nextProps.editing;
    }
    if (isTextChanged || isEditingChanged) {
      this.setState(nextState as State);
    }
  }

  componentDidUpdate(prevProps: Props, prevState: Props) {
    let inputElem = ReactDOM.findDOMNode(this.refs.input);
    if (this.state.editing && !prevState.editing) {
      (inputElem as HTMLInputElement).focus();
      selectInputText(inputElem as HTMLInputElement);
    } else if (this.state.editing && prevProps.text != this.props.text) {
      this.finishEditing();
    }
  }

  startEditing = (e: React.MouseEvent<HTMLInputElement>) => {
    if (this.props.stopPropagation) {
      e.stopPropagation()
    }
    this.setState({ editing: true, text: this.props.text });
  };

  finishEditing = () => {
    if (this.isInputValid(this.state.text) && this.props.text != this.state.text) {
      this.commitEditing();
    } else if (this.props.text === this.state.text || !this.isInputValid(this.state.text)) {
      this.cancelEditing();
    }
  };

  cancelEditing = () => {
    this.setState({ editing: false, text: this.props.text });
  };

  commitEditing = () => {
    this.setState({ editing: false, text: this.state.text });
    let newProp = {};
    newProp[this.props.paramName] = this.state.text;
    this.props.change(newProp);
  };

  clickWhenEditing = (e: React.MouseEvent<HTMLInputElement>) => {
    if (this.props.stopPropagation) {
      e.stopPropagation();
    }
  };

  isInputValid = (text: string) => {
    return (text.length >= (this.state.minLength || InlineEdit.defaultProps.minLength) && text.length <= (this.state.maxLength || InlineEdit.defaultProps.maxLength));
  };

  keyDown = (event: React.KeyboardEvent) => {
    if (event.keyCode === 13) {
      this.finishEditing();
    } else if (event.keyCode === 27) {
      this.cancelEditing();
    }
  };

  textChanged = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({
      text: event.target.value.trim()
    });
  };

  render() {
    if (this.props.isDisabled) {
      const Element = this.props.staticElement || InlineEdit.defaultProps.staticElement as any;
      return <Element
        className={this.props.className}
        style={this.props.style} >
        {this.state.text || this.props.placeholder}
      </Element>;
    } else if (!this.state.editing) {
      const Element = this.props.staticElement || InlineEdit.defaultProps.staticElement as any;
      return <Element
        className={this.props.className}
        onClick={this.startEditing}
        tabIndex={this.props.tabIndex}
        style={this.props.style}
        title={this.props.title}>
        {this.state.text || this.props.placeholder}
      </Element>;
    } else {
      const Element = this.props.editingElement || InlineEdit.defaultProps.editingElement as any;
      return <Element
        onClick={this.clickWhenEditing}
        onKeyDown={this.keyDown}
        onBlur={this.finishEditing}
        className={this.props.activeClassName}
        placeholder={this.props.placeholder}
        defaultValue={this.state.text}
        onChange={this.textChanged}
        style={this.props.style}
        ref="input" />;
    }
  }
}

export { InlineEdit }