import { html5LinkOnClick } from 'takeme';
import * as React from 'react';
import classNames from 'classnames';

export const Link: React.SFC<{ href: string, className?: string, target?: string, isActive?: boolean }> = (props) => {

  var classes = {};
  classes[props.className || ''] = true;
  classes['active'] = props.isActive;

  return <a onClick={(e) => html5LinkOnClick({ event: e.nativeEvent })}
    className={classNames(classes)}
    href={props.href}
    target={props.target}
    children={props.children}
  />
}
