import { observable, action, computed, runInAction } from "mobx";
import { fetchJson, avg, sum, group, orderBy, toDict } from '../Utils';
import underscore from 'underscore';
import { globalState } from "./GlobalState";
import localStorage from 'mobx-localstorage';

let colors = [
  '#e8081e',
  '#fd7e14',
  '#ffc107',
  '#28a745',
  '#007bff',
  '#6610f2',
  '#20c997',
  '#17a2b8',
  '#6c757d',
];

export enum AutoSelectOnShot {
  No = 'no',
  Yes = 'yes',
  LastOnly = 'last-only',
  Last5 = 'last-5',
  LastClub = 'last-club'
};

export enum SpinNumbers {
  SideBack = 0,
  AxisTotal = 1
};

let defaultSessionShotSettings: SessionShotSettings = {
  isMetres: undefined,
  autoSelectOnShot: AutoSelectOnShot.Yes,
  showClubDataOnFirst: false,
  showDispersionCircles: true,
  showDispersionCirclesByClub: false,
  spinNumbers: SpinNumbers.SideBack
};

export class SessionShotsState {
  sessionId: string;
  archivedFilter = (shot: Shot) => !shot.archived || this.filterSettings.showArchived;

  @observable public shots: { [shotId: string]: Shot } = {};
  @observable public isOwnSession: boolean = false;
  
  oldDisplayTracer: boolean | null = null;

  @computed public get shotsInOrder() {
    return orderBy(Object.keys(this.shots).map(x => this.shots[x]), x => x.date).filter(x => this.archivedFilter(x)).map(x => x.id);
  }

  @computed public get shotsInReverseOrder() {
    return orderBy(Object.keys(this.shots).map(x => this.shots[x]), x => x.date, false).filter(x => this.archivedFilter(x)).map(x => x.id);
  }

  @computed public get shotsCount() {
    return this.shotsInOrder.length;
  }

  @observable public loading: boolean = false;
  @observable public openCloseStates: { [shotId: string]: boolean } = {};
  @observable public groupedByClub: GroupedBy = GroupedBy.Default;
  @observable public showClubData: boolean = false;
  @observable public selectedShots: { [id: string]: boolean } = {};
  @observable public colorIndex: number = 0;
  @observable public clubColors: { [id: string]: string } = {};
  @observable public connectedState: { label: string, text: string } = { label: "secondary", text: "Idle" };
  @observable public cachedTracerPoints: { [id: string]: BallFlightData } = {};
  @observable public isSettingsOpen: boolean = false;
  @observable public isFiltersOpen: boolean = false;
  @observable public isVisualOpen: boolean = false;
  @observable public animateLast: boolean = false;

  @computed public get settings(): SessionShotSettings {
    return Object.assign({}, defaultSessionShotSettings, localStorage.getItem('shot.settings') as SessionShotSettings);
  };

  @observable public filterSettings: SessionShotFilterSettings = {
    showArchived: false
  };

  @observable public sessionName: string = '';
  @observable public currentHoverShot: string | null = null;

  constructor(sessionId: string) {
    this.sessionId = sessionId;
  }

  @action
  async routed(shotId: string | undefined) {
    this.selectedShots = {};
    await this.setInitialData();
    if (shotId !== undefined &&
      (this.shots[shotId] !== undefined
        || (+shotId > 0 && +shotId <= this.shotsCount)
        || shotId.indexOf(',') > 0)) {

      let shots = [];
      if (shotId.indexOf(',') > 0) {
        shots = shotId.split(',').filter(x => +x > 0 && +x < this.shotsCount).map(x => this.shots[this.shotsInOrder[+x - 1]]);
      }
      else if (!isNaN(+shotId)) {
        shots = [this.shots[this.shotsInOrder[+shotId - 1]]];
      }
      else {
        shots = [this.shots[shotId]];
      }
      await this.setSelected(shots, true);
    }
  }

  @action
  setSetting<T extends keyof SessionShotSettings>(data: Pick<SessionShotSettings, T>) {
    var settings = Object.assign(this.settings, data);
    localStorage.setItem('shot.settings', settings);
  }

  setFilters<T extends keyof SessionShotFilterSettings>(data: Pick<SessionShotFilterSettings, T>) {
    this.filterSettings = Object.assign(this.filterSettings, data);
  }

  @action
  toggleSettings() {
    this.isSettingsOpen = !this.isSettingsOpen;
  }

  @action
  toggleFilters() {
    this.isFiltersOpen = !this.isFiltersOpen;
  }

  @action
  toggleVisual() {
    this.isVisualOpen = !this.isVisualOpen;
  }

  @action
  async setInitialData() {
    this.loading = true;

    var data = await fetchJson<Data>(`/webapi/sessions/${this.sessionId}`);

    runInAction(() => {
      this.sessionName = data.sessionName;
      this.isOwnSession = data.isOwnSession;
  
      if (data.shots.length > 0 && data.shots[0].shotData.clubData.clubSpeed > 0) {
        this.openCloseStates[data.shots[0].id] = this.settings.showClubDataOnFirst;
      }
  
      this.shots = toDict(data.shots, x => x.id);
      this.loading = false;
    });    
  }

  @action
  async updateShot(shotId: string, club: string) {
    fetchJson(`/webapi/sessions/${this.sessionId}/shot-update/${shotId}`, { method: 'POST', data: { club: club } });
    this.shots[shotId].club = club;
    if (this.selectedShots[this.shots[shotId].id]) {
      this.setSelected([{ id: shotId, club: club }], true);
    }
    this.cachedTracerPoints[shotId] && (this.cachedTracerPoints[shotId].club = club);
  }

  @action
  async archiveShot(shotId: string) {
    fetchJson(`/webapi/sessions/${this.sessionId}/shot-archive/${shotId}`, { method: 'POST', data: { archive: !this.shots[shotId].archived } });
    this.shots[shotId].archived = !this.shots[shotId].archived;
    this.selectedShots[shotId] = false;
  }

  @action
  rankShot(shotId: string, rating: number) {
    fetchJson(`/webapi/sessions/${this.sessionId}/shot-rate/${shotId}`, { method: 'POST', data: { rating: rating } });
    this.shots[shotId].rating = rating;
  }

  @action
  onShot(shot: Shot) {
    let existing = this.shots[shot.id] !== undefined;
    if (existing) {
      this.shots[shot.id] = shot;
    }
    else {
      this.openCloseStates = { [shot.id]: this.settings.showClubDataOnFirst };
      this.shots[shot.id] = shot;
      this.animateLast = false;

      let selectedShots = this.selectedTracerPoints.map(x => ({ id: x.shotId, club: x.club }));

      switch (this.settings.autoSelectOnShot) {
        case AutoSelectOnShot.Yes:
          this.setSelected([this.shots[this.shotsInReverseOrder[0]]], true);
          break;
        case AutoSelectOnShot.LastOnly:
          this.setSelected(selectedShots, false);
          this.setSelected([this.shots[this.shotsInReverseOrder[0]]], true);
          break;
        case AutoSelectOnShot.Last5:
          this.setSelected(selectedShots, false);
          this.setSelected(this.shotsInReverseOrder.map(x => this.shots[x]).slice(0, 5), true);
          this.animateLast = true;
          break;
        case AutoSelectOnShot.LastClub:
          this.setSelected(selectedShots, false);
          this.setSelected(this.shotsInOrder.map(x => this.shots[x]).filter(x => x.club === this.shots[this.shotsInReverseOrder[0]].club), true);
          this.animateLast = true;
          break;
      }
    }
  }

  @action
  setLiveConnection() {
    this.connectedState = {
      label: 'success',
      text: 'Live'
    };
  }

  @action
  toggleClubData(shotId: string) {
    this.openCloseStates[shotId] = !this.openCloseStates[shotId] ? true : false;
  }

  @action
  async toggleGroupBy(grouped: GroupedBy) {
    if (this.groupedByClub == grouped)
      return;

    this.groupedByClub = grouped;

    if (grouped === GroupedBy.Summary) {
      this.assignColor(this.shotsInReverseOrder.map(x => this.shots[x]), x => true);
      this.oldDisplayTracer = globalState.isTracerActive;
      globalState.toggleTracer(false);
      await this.getTracerPoints(this.shotsInOrder);
    }
    else {
      if (this.oldDisplayTracer != null) {
        globalState.toggleTracer(this.oldDisplayTracer);
        this.oldDisplayTracer = null;
      } 
    }
  }

  @action
  toggleDataType(show: boolean) {
    this.showClubData = show;
  }

  @action
  setHoverShot(shotId: string) {
    this.currentHoverShot = shotId;
  }

  @action
  setUnHoverShot() {
    this.currentHoverShot = null;
  }

  @action
  async setSelected(shots: { id: string, club: string }[], selected?: boolean) {
    var selectedShots: { [id: string]: boolean } = {};
    var filteredShots = shots.filter(x => !this.shots[x.id].archived);
    filteredShots.forEach(x => selectedShots[x.id] = selected !== undefined ? selected : !this.selectedShots[x.id]);
    this.assignColor(filteredShots, x => selectedShots[x]);
    this.selectedShots = { ...this.selectedShots, ...selectedShots };
    await this.getTracerPoints(Object.keys(selectedShots));
  }

  private assignColor(shots: { id: string; club: string; }[], selectedShots: (id: string) => boolean) {
    shots.forEach((x => {
      if (!this.clubColors[x.club]) {
        this.clubColors[x.club] = colors[this.colorIndex % colors.length];
        this.colorIndex = this.colorIndex + (selectedShots(x.id) ? 1 : -1);
      }
    }));
  }

  public async getTracerPoints(tracerShots: string[]) {
    var newShots = tracerShots.filter(x => Object.keys(this.cachedTracerPoints).findIndex(z => z === x) === -1);
    if (newShots.length > 0) {
      var newPoints = await fetchJson<BallFlightData[]>(`/webapi/sessions/${this.sessionId}/tracer-data`, { method: "POST", data: { shotIds: newShots } })
      if (newPoints.length > 0) {
        var points: { [id: string]: BallFlightData } = {};
        newPoints.forEach(x => { points[x.shotId] = x; });
        this.cachedTracerPoints = { ...this.cachedTracerPoints, ...points };
      }
    }
  }

  @computed
  get selectedTracerPoints() {
    return this.shotsInOrder
      .filter(x => this.selectedShots[x] && this.cachedTracerPoints[x])
      .map(x => this.cachedTracerPoints[x]);
  }

  @computed
  get summaryTracerPoints() {
    return this.shotsInOrder
      .filter(x => this.cachedTracerPoints[x])
      .map(x => this.cachedTracerPoints[x]);
  }

  @computed
  get selectedShotClubData() {
    return this.shotsInOrder
      .filter(x => this.selectedShots[x]
        && (this.shots[x].shotData.clubData.horizontalImpact != null || this.shots[x].shotData.clubData.verticalImpact != null))
      .map(x => this.shots[x]);
  }

  carryDispersion(tracerPoints: BallFlightData[]): CarryDispersion {
    var list = tracerPoints;
    if (list.length == 0) {
      return {
        x: 0,
        y: 0,
        dispersionsPerClub: []
      };
    }

    var carrys = list.map(x => x.points.length > 0 ? calcCarry(x.points[x.points.length - 1].x, x.points[x.points.length - 1].y) : 0);
    var dispCarry = carrys.length > 0 ? underscore.max(carrys) - underscore.min(carrys) : 0;

    var clubValues = list.map(x => ({ club: x.club, dist: x.points.length > 0 ? x.points[x.points.length - 1].x : 0, offline: x.points.length > 0 ? x.points[x.points.length - 1].y : 0 }));
    var offlines = clubValues.map(x => x.offline);
    var dispLeftRight = clubValues.length > 0 ? underscore.max(offlines) - underscore.min(offlines) : 0;

    var groups = group(clubValues, x => this.settings.showDispersionCirclesByClub ? x.club : '__allClubs').map(x => {
      var avgDistances = avg(x.values.map(y => y.dist));
      var avgOfflines = avg(x.values.map(y => y.offline));

      var sumSqrtXs = sum(x.values.map(y => Math.pow(y.dist - avgDistances, 2)));
      var sumSqrtYs = sum(x.values.map(y => Math.pow(y.offline - avgOfflines, 2)));

      return {
        club: x.key,
        selected: x.values.length,
        avgXs: avgDistances,
        xStdDev: Math.max(Math.sqrt(sumSqrtXs / x.values.length), 15),
        avgYs: avgOfflines,
        yStdDev: Math.max(Math.sqrt(sumSqrtYs / x.values.length), 15)
      }
    });

    var disp = {
      x: dispCarry,
      y: dispLeftRight,
      dispersionsPerClub: groups
    };

    return disp;
  }

  @computed get displayInMetres() {
    return this.settings.isMetres == undefined
      ? globalState.auth.user.isMetres
      : this.settings.isMetres;
  }
}

export function calcCarry(x: number, y: number) {
  return Math.sqrt(Math.pow(x, 2) + Math.pow(y, 2));
}

export interface CarryDispersion {
  x: number;
  y: number;
  dispersionsPerClub: {
    club: string;
    selected: number;
    avgXs: number;
    xStdDev: number;
    avgYs: number;
    yStdDev: number;
  }[]
}

export interface Shot {
  id: string;
  date: string;
  shotData: ShotData;
  club: string;
  archived: boolean;
  rating: number;
}

export interface ShotData {
  smashFactor: any;
  ballData: BallData;
  clubData: ClubData;
}

export interface BallData {
  peakHeightMts: number;
  carryMts: number;
  peakHeight: number;
  ballSpeed: number;
  carry: number;
  launchAngle: number;
  launchDirection: number;
  backSpin: number;
  totalSpin: number;
  sideSpin: number;
  spinAxis: number;
  offline: number;
  offlineMts: number;
}

export interface ClubData {
  angleOfAttack: number;
  loft: number;
  clubPath: number;
  faceToPath: number;
  faceToTarget: number;
  closingRate: number;
  lie: number;
  horizontalImpact: number;
  verticalImpact: number;
  clubSpeed: number;
}

export enum GroupedBy {
  Default,
  Grouped,
  ByClub,
  Summary
}

export interface Data {
  sessionName: string;
  isOwnSession: boolean;
  shots: Shot[];
}

export interface BallFlightData {
  shotId: string;
  club: string;
  points: Point[];
  animate: boolean;
}

export interface Point {
  x: number;
  y: number;
  z: number;
}

interface SessionShotSettings {
  isMetres: boolean | undefined;
  showClubDataOnFirst: boolean;
  showDispersionCircles: boolean;
  autoSelectOnShot: AutoSelectOnShot;
  showDispersionCirclesByClub: boolean;
  spinNumbers: SpinNumbers;
}

interface SessionShotFilterSettings {
  showArchived: boolean;
}

