import * as React from 'react';
import './SignIn.css';

function SignIn() {
  return (
    <div className="discord-container">
      <div className="block">
        <div className="block__header">
          Signin
        </div>
        <div className="block__body">
          <a href="/signin-please" className="login_button">Login through Discord</a>
        </div>
        <div className="block__footer">
          This website is not affiliated with <a href="https://discordapp.com">discord inc.</a>
        </div>
      </div>
    </div>
  );
}

export { SignIn }