import * as React from 'react';
//import { Link } from 'react-router-dom';
import './NavMenu.css';
import { AuthDetails } from '../App';
import { Link } from './Link';
import { routeState, Route, links } from '../Stores/RouteState';
import classNames from 'classnames';
import { observer } from 'mobx-react';
import { globalState } from '../Stores/GlobalState';

interface Props {
  auth: AuthDetails
}

@observer
export class NavMenu extends React.Component<Props> {
  render() {

    var menuNavClasses = classNames({
      'collapse': true,
      'show': routeState.showMenu
    });

    var navBarClasses = classNames({
      'navbar navbar-dark': true,
      'underline': routeState.showMenu || !globalState.isSmallScreen
    });

    var navItemClasses = classNames({
      'nav-item': true,
      'px-3': !globalState.isSmallMenu
    });

    return (
      <React.Fragment>
        <div className={navBarClasses}>
          <div className="navbar-brand logo mx-auto">
            {!globalState.isSmallMenu ? "Schogolf" : "SG"}
          </div>
          <button className="navbar-toggler navbar-toggler-right" onClick={() => routeState.toggleMenu()}>
            <span className="navbar-toggler-icon"></span>
          </button>
        </div>
        <div className={menuNavClasses} id="menuNav">
          <ul className="nav flex-column">
            <li className={navItemClasses}>
              <Link href={links.home()} className="nav-link" isActive={Route.home === routeState.linkRoute}>
                <span className="oi oi-home" aria-hidden="true"></span> 
                {!globalState.isSmallMenu && "Home"}
              </Link>
            </li>
            {this.props.auth.isAuthenticated &&
              <li className={navItemClasses}>
                <Link href={links.sessions()} className="nav-link" isActive={Route.sessions === routeState.linkRoute}>
                  <span className="oi oi-list-rich" aria-hidden="true"></span> 
                  {!globalState.isSmallMenu && "Sessions"}
                </Link>
              </li>
            }
            {this.props.auth.isAuthenticated &&
              <li className={navItemClasses}>
                <Link href={links.profile()} className="nav-link" isActive={Route.profile === routeState.linkRoute} >
                  <span className="oi oi-person" aria-hidden="true"></span> 
                  {!globalState.isSmallMenu && "Profile"}
                </Link>
              </li>
            }
            {this.props.auth.isAuthenticated &&
              <li className={navItemClasses}>
                <Link href={links.help()} className="nav-link" isActive={Route.help === routeState.linkRoute}>
                  <span className="oi oi-info" aria-hidden="true"></span> 
                  {!globalState.isSmallMenu && "Help"}
                </Link>
              </li>
            }
            {!this.props.auth.isAuthenticated &&
              <li className={navItemClasses}>
                <Link href={links.signin()} className="nav-link" isActive={Route.signin === routeState.linkRoute}>
                  <span className="oi oi-account-login" aria-hidden="true"></span> 
                  {!globalState.isSmallMenu && "SignIn"}
                </Link>
              </li>
            }
            {this.props.auth.isAuthenticated &&
              <li className={navItemClasses}>
                <a href="/signout" className="nav-link">
                  <span className="oi oi-account-logout" aria-hidden="true"></span> 
                  {!globalState.isSmallMenu && "SignOut"}                  
                </a>
              </li>
            }
          </ul>
        </div>
      </React.Fragment>
    );
  }
}
