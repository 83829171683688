import * as Moment from 'moment';
import axios, { AxiosRequestConfig } from 'axios';

var axiosInstance = axios.create();

async function fetchJson<T = any>(url: string, init?: AxiosRequestConfig | undefined): Promise<T> {
    var defaults = Object.assign({}, init);

    try {
      var result = await axiosInstance(url, defaults);
      // if (result.status === 302) {
      //   window.location = response.url
      // }
      return result.data;
    }
    catch {
      return {} as T;
    }
}

function formatDate(date: string, dateType: DateType) {
  let d = Moment.utc(date).local();  
  let returnDate = d.format("YYYY-MM-DD h:mm:ss a");
  if (dateType == DateType.Date) 
    returnDate = d.format("YYYY-MM-DD");
  else if (dateType == DateType.Time)
    returnDate = d.format("H:mm:ss");
  return returnDate;
}

function avg(list: number[]) {
  return sum(list) / (list.length || 1);
}

function sum(list: number[]) {
  return list.reduce((memo, num) => memo + num, 0);
}

function orderBy<T, T2>(list: T[], selector: (item: T) => T2, asc: boolean = true) {
  function sortThings(a: T2, b: T2) {
    var result = a > b ? 1 : b > a ? -1 : 0;
    return asc ? result : result * -1;
  }

  return list.sort((x, y) => sortThings(selector(x), selector(y)));
}

function toDict<T>(list: T[], selector: (item: T) => string) {
  var dict: { [id: string]: T } = {};
  for (let i=0; i<list.length; i++) {
    dict[selector(list[i])] = list[i];
  }
  return dict;
}

function orderedGroup<T, T2>(list: T[], selector: (item: T) => T2) {
  let groupedList: { key: T2, values: T[] }[] = [];
  list.forEach(x => {
    if (groupedList.length > 0) {
      let lastItem = groupedList[groupedList.length - 1];
      if (JSON.stringify(lastItem.key) == JSON.stringify(selector(x))) {
        lastItem.values.push(x);
        return;
      }
    }
    let item = {
      key: selector(x),
      values: [x]
    };
    groupedList.push(item);
  });
  return groupedList;
}

function group<T, T2>(list: T[], selector: (item: T) => T2) {
  let groupedList: { key: T2, values: T[] }[] = [];
  list.forEach(x => {
    var index = groupedList.findIndex(z => JSON.stringify(z.key) === JSON.stringify(selector(x)));
    if (index >= 0) {
      groupedList[index].values.push(x);
    }
    else {
      let item = {
        key: selector(x),
        values: [x]
      };
      groupedList.push(item);
    }
  });
  return groupedList;
}

var arrayMath = {
  all: function(array: boolean[]) {
    for(var i = 0; i<array.length; i++) {
      if (!array[i])
        return false;
    }
    return true;
  },

  max: function(array: number[]) {
    return Math.max.apply(null, array);
  },

  min: function(array: number[]) {
    return Math.min.apply(null, array);
  },

  range: function(array: number[]) {
    return arrayMath.max(array) - arrayMath.min(array);
  },

  midrange: function(array: number[]) {
    return arrayMath.range(array) / 2;
  },

  sum: function(array: number[]) {
    var num = 0;
    for (var i = 0, l = array.length; i < l; i++) num += array[i];
    return num;
  },

  mean: function(array: number[]) {
    return arrayMath.sum(array) / array.length;
  },

  median: function(array: number[]) {
    array.sort(function(a, b) {
      return a - b;
    });
    var mid = array.length / 2;
    return mid % 1 ? array[mid - 0.5] : (array[mid - 1] + array[mid]) / 2;
  },

  modes: function(array: number[]) {
    if (!array.length) return [];
    var modeMap = {},
      maxCount = 1,
      modes = [array[0]];

    array.forEach(function(val) {
      if (!modeMap[val]) modeMap[val] = 1;
      else modeMap[val]++;

      if (modeMap[val] > maxCount) {
        modes = [val];
        maxCount = modeMap[val];
      }
      else if (modeMap[val] === maxCount) {
        modes.push(val);
        maxCount = modeMap[val];
      }
    });
    return modes;
  },

  variance: function(array: number[]) {
    var mean = arrayMath.mean(array);
    return arrayMath.mean(array.map(function(num) {
      return Math.pow(num - mean, 2);
    }));
  },

  standardDeviation: function(array: number[]) {
    return Math.sqrt(arrayMath.variance(array));
  },

  meanAbsoluteDeviation: function(array: number[]) {
    var mean = arrayMath.mean(array);
    return arrayMath.mean(array.map(function(num) {
      return Math.abs(num - mean);
    }));
  },

  zScores: function(array: number[]) {
    var mean = arrayMath.mean(array);
    var standardDeviation = arrayMath.standardDeviation(array);
    return array.map(function(num) {
      return (num - mean) / standardDeviation;
    });
  }
};

export enum DateType {
  Full,
  Date,
  Time
}

export { fetchJson, formatDate, avg, sum, orderedGroup, group, orderBy, toDict, arrayMath };