import * as React from 'react';
import { Layout } from './Components/Layout';
import { Home } from './Components/Home';
import { Profile } from './Components/Profile';
import { SignIn } from './Components/SignIn';
import { Sessions } from './Components/Sessions';
import { SessionShots } from './Components/SessionShots';
import { Help } from './Components/Help';
import { fetchJson } from './Utils';
import { observer } from 'mobx-react';
import { routeState } from './Stores/RouteState';
import { globalState } from './Stores/GlobalState';

export interface User {
  isMetres: boolean;
  isPremium: boolean;
  apiKey: string;
  username: string;
}

export interface AuthDetails {
  user: User;
  isAuthenticated: boolean;
}

@observer
class App extends React.Component {
  constructor(props: any) {
    super(props);
    globalState.loadAuth();
  }

  updateWindowDimensions() {
    globalState.setWindowDimensions({ width: window.innerWidth, height: window.innerHeight });
  }

  componentDidMount() {
    this.updateWindowDimensions();
    window.addEventListener('resize', () => this.updateWindowDimensions());
  }
  
  componentWillUnmount() {
    window.removeEventListener('resize', () => this.updateWindowDimensions());
  }  

  public render() {
    return (
      <Layout auth={globalState.auth}>
        <Page />
      </Layout>
    );
  }
}

const Page = observer(() => {
  setTimeout(() => setMainMargin(), 1000);
  switch (routeState.route) {
    case 'home': return <Home />
    case 'signin': return <SignIn />
    case 'profile': return <Profile />
    case 'help': return <Help />
    case 'sessions': return <Sessions />
    case 'sessionShots': return <SessionShots />
    default:
      //const _ensure: never = routeState.route;
      return <noscript />
  }
});

let setMainMargin = function() {
  let height = $('.bottombar').outerHeight() || 0;
  $('.content').css('margin-bottom', height);
};

$(window).on('resize', function() {
  setMainMargin();
});

$(function() {
  setTimeout(() => setMainMargin(), 1000);
});

export default App;
