import * as React from 'react';
import { Link } from './Link';
import { links } from '../Stores/RouteState';
import { sessionState, Session } from '../Stores/SessionState';
import { observer } from 'mobx-react';
import { formatDate, DateType } from '../Utils';
import { If } from 'react-if';

const SessionEdit = observer(() => {

  let submit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    sessionState.createSessionSubmitted();
  };

  return (
    <div className="col-sm-4">
      <div className="card" style={{position:'sticky', top: 15}}>
        <div className="card-header">
          <h3 className="card-title">{sessionState.editSessionId === '' ? 'Create' : 'Edit'} Session</h3>
        </div>
        <div className="card-body">
          <form onSubmit={e => submit(e)}>
            <div className="form-group">
              <label htmlFor="InputName">Name</label>
              <input type="text" className="form-control" id="InputName" aria-describedby="nameHelp" placeholder="Name" value={sessionState.editSessionData.name} onChange={(e) => sessionState.setNewSession({ name: e.target.value })} />
              <small id="nameHelp" className="form-text text-muted">Describe your session.</small>
            </div>
            <div className="form-group">
              <div className="form-check">
                <label className="form-check-label">
                  <input type="checkbox" className="form-check-input" checked={sessionState.editSessionData.isPrivate} onChange={e => sessionState.setNewSession({ isPrivate: e.target.checked })} />
                  Is Private?
              </label>
              </div>
            </div>
            <div className="float-right">
              <button type="button" className="btn btn-secondary" onClick={() => sessionState.cancelCreateSession()}>Cancel</button>
              {' '}
              <button type="submit" className="btn btn-primary">Submit</button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
});

@observer
export class Sessions extends React.Component {
  constructor(props: any) {
    super(props);
    sessionState.getSessions();
  }

  static renderSessions(sessions: Session[]) {
    return (
      <div className="row">
        <div className="col">
          <div className="card">
            <div className="card-header">
              <div>
                <button className="btn btn-primary float-right" onClick={() => sessionState.createSession()}>Create Session</button>
              </div>
              <h3 className="card-title">Sessions</h3>
              <p>These are your sessions.</p>
            </div>
            <div className="card-body">
              <div className="table-responsive-lg">
                <table className='table'>
                  <thead>
                    <tr>
                      <th>Date</th>
                      <th>Session Id</th>
                      <th>Name</th>
                      <th>Shots</th>
                      <th>Is Private?</th>
                      <th></th>
                    </tr>
                  </thead>
                  <tbody>
                    {sessions.map(session =>
                      <tr key={session.sessionId}>
                        <td>{formatDate(session.startedAt, DateType.Date)}</td>
                        <td>{session.sessionId}</td>
                        <td>{session.name}</td>
                        <td>{session.shotCount}</td>
                        <td>{session.isPrivate ? "Yes" : "No"}</td>
                        <td className="text-right">
                          <div className="btn-group">                           
                            <Link className="btn btn-success btn-xs" href={links.sessionShots(session.sessionId)}>Shots</Link>
                            <button className="btn btn-secondary btn-xs dropdown-toggle" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"><span className="sr-only">Toggle Dropdown</span></button>
                            <div className="dropdown-menu dropdown-menu-right" aria-labelledby="dropdownMenuButton">
                              <a className="dropdown-item" href="javascript:;" onClick={() => sessionState.editSession(session.sessionId)}>Edit</a>
                              <If condition={session.shotCount == 0}>
                                <a className="dropdown-item" href="javascript:;" onClick={() => sessionState.deleteSession(session.sessionId)}>Delete</a>}
                              </If> 
                            </div>
                          </div>
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
        {sessionState.showCreateSession && <SessionEdit />}
      </div>
    );
  }

  render() {
    let contents = sessionState.isLoading
      ? <p><em>Loading...</em></p>
      : Sessions.renderSessions(sessionState.orderedSessions);

    return (
      <div>
        {contents}
      </div>
    );
  }
}
