import * as React from 'react';
import { navigate } from 'takeme';
import { homeState } from '../Stores/HomeState';
import { links } from '../Stores/RouteState';
import { observer } from 'mobx-react';
import { globalState } from '../Stores/GlobalState';

@observer
class Home extends React.Component {
  onSubmit(e: React.FormEvent<HTMLElement>) {
    e.preventDefault();
    if (homeState.sessionId != '') {
      navigate(links.sessionShots(homeState.sessionId));
    }
  }

  componentDidMount() {
    homeState.loadVersion();
  }

  render() {
    return (
      <div>
        <div className="card">
          <div className="card-header">
            <h3 className="card-title">Welcome!</h3>
          </div>
          <div className="card-body">
            Your online shot tracking software with integrations directly to GC2 or via the OGT interface.
          </div>
        </div>
        <div className="row">
          <div className="col-sm-6 col-xl-4">
            <div className="card">
              <div className="card-header">
                <h4 className="card-title">View Live Sessions</h4>
              </div>
              <div className="card-body">
                <form onSubmit={e => this.onSubmit(e)}>
                  <div className="form-group">
                    <input value={homeState.sessionId} onChange={(x) => homeState.updateSessionId(x.target.value)} type="text" className="form-control" id="sessionId" aria-describedby="sessionIdHelp" placeholder="Enter Session Id" />
                    <small id="sessionIdHelp" className="form-text text-muted">To view an existing public schedule enter the id in above.</small>
                  </div>
                  <button type="submit" className="btn btn-primary float-right">View Session</button>
                </form>
              </div>
            </div>
          </div>
          {globalState.auth.isAuthenticated && 
          <div className="col-sm-6 col-xl-8">
            <div className="card">
              <div className="card-header">
                <h4 className="card-title">Schogolf Client</h4>
              </div>
              <div className="card-body">
                <div>
                  <p>
                    The latest version of the client is <strong>{homeState.isLoadingVersionId ? 'awesome' : homeState.versionId}</strong>. Click <a target="_blank" href="/client_files/Schogolf.zip">here</a> to download or click update directly in the application.
                  </p>
                  <p>
                    For help or just getting started click on the menu item <strong>HELP</strong>.
                  </p>
                </div>
              </div>
            </div>
          </div>
          }
        </div>
      </div>
    );
  }
}

export { Home };