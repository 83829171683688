import * as React from 'react';
import { NavMenu } from './NavMenu';
import { AuthDetails } from '../App';
import { BallFlight } from './BallFlight';
import { globalState } from '../Stores/GlobalState';
import { observer } from 'mobx-react';
import classNames from 'classnames';
import { routeState } from '../Stores/RouteState';
import { sessionState } from '../Stores/SessionState';

interface Props {
  auth: AuthDetails
}

@observer
export class Layout extends React.Component<Props> {
  render() {

    let contentClassNames = classNames({
      'content': true,
      'px-1': globalState.isSmallScreen,
      'px-3': !globalState.isSmallScreen,
      'has-bottombar': globalState.showBottomBar
    });

    let sideBarClasses = classNames({
      'sidebar': true,
      'small': globalState.isSmallMenu
    });

    let bottomBarClasses = classNames({
      'bottombar': true,
      'small': globalState.isSmallMenu
    })

    function getBallFlight() {
      var shotState = sessionState.get(routeState.sessionId);
      return <BallFlight 
        currentHoverShotId={shotState.currentHoverShot} 
        animateLast={shotState.animateLast}
        ballFlightData={shotState.selectedTracerPoints}
        carryDispersion={shotState.carryDispersion(shotState.selectedTracerPoints)}
        clubColors={shotState.clubColors}
        displayDispersionCircles={shotState.settings.showDispersionCircles}
        displayInMetres={shotState.displayInMetres}
      />
    }

    return (
      <React.Fragment>
        <div className={sideBarClasses} 
          data-color="blue" 
          onMouseEnter={() => globalState.toggleCollapsedMenu()} 
          onMouseLeave={() => globalState.toggleCollapsedMenu()}>
          <NavMenu auth={this.props.auth} />
        </div>
        <div className="main">
          <div className={contentClassNames}>
            {this.props.children}
          </div>
          {globalState.showBottomBar &&
            <div id="bottomBar" className={bottomBarClasses}>
              {globalState.isTracerActive && getBallFlight()}
            </div>
          }
        </div>
      </React.Fragment>
    );
  }
}
