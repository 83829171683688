import * as React from 'react';
import { sessionState } from '../Stores/SessionState';
import { observer } from 'mobx-react';
import { SessionShotsState, AutoSelectOnShot } from '../Stores/SessionShotState';
import { Modal, ModalHeader, ModalBody } from 'reactstrap';
import { avg } from '../Utils';
import { If, Then } from 'react-if';

interface Props {
  sessionId: string
}

@observer
export class ShotVisual extends React.Component<Props> {
  store: SessionShotsState;

  constructor(props: Props) {
    super(props);
    this.store = sessionState.get(props.sessionId)
  }

  render() {
    var shots = this.store.selectedShotClubData;
    var lie = shots.length > 0 ? avg(shots.map(x => x.shotData.clubData.lie)) : 0;

    // var shots = [
    //   { shotData: { clubData: { horizontalImpact: 50, verticalImpact: 23 }}},
    //   { shotData: { clubData: { horizontalImpact: -50, verticalImpact: -23 }}},
    //   { shotData: { clubData: { horizontalImpact: -50, verticalImpact: 23 }}},
    //   { shotData: { clubData: { horizontalImpact: 50, verticalImpact: -23 }}},
    // ] as any[];

    // shots = [
    //   { club: '8i', shotData: { clubData: { horizontalImpact: 25, verticalImpact: 20 }}},
    //   { club: '8i', shotData: { clubData: { horizontalImpact: -25, verticalImpact: -20 }}},
    //   { club: '8i', shotData: { clubData: { horizontalImpact: -25, verticalImpact: 29 }}},
    //   { club: '8i', shotData: { clubData: { horizontalImpact: 25, verticalImpact: -20 }}},
    // ] as any[];

    const isWood = (club: string | null) => {
      if (club == null)
        return true;
      return /\b[^0-9]?[0-9][wW]/.test(club) || club.toLowerCase().indexOf('dr') >= 0;
    }

    var allSelectedShots = this.store.shotsInReverseOrder.filter(x => this.store.selectedShots[x]).map(x => this.store.shots[x]);
    var isWoodShot = allSelectedShots.length > 0 ? isWood(allSelectedShots[0].club) : true;

    var imageOffsetX = isWoodShot ? 50 : 102;
    var offsetX = 50;
    var offsetY = isWoodShot ? 0 : 12;
    var lineOffset = 80;
    var baseCenterX = 240;
    var baseCenterY = 226;
    var centerX = offsetX + baseCenterX;
    var centerY = offsetY + baseCenterY;
    var factor = isWoodShot ? 3.4 : 4.1;
    var image = `/images/${isWoodShot ? 'driver' : 'iron'}-front-white.png`;

    const renderStrike = () => (
      <svg viewBox="0 0 600 338" style={{ borderRadius: 5, backgroundColor: "#fff" }}>
        <g style={{ transform: `rotate(${lie}deg)`, transformOrigin: `${centerX}px ${centerY}px` }}>
          <image x={imageOffsetX} xlinkHref={image} height="338" width="550" />
          <polyline
            points={`${offsetX},${centerY} 500,${centerY}`}
            stroke="gray"
            strokeWidth="1"
            strokeDasharray="2"
            fill="none"
            style={{ transform: `rotate(${-lie}deg)`, transformOrigin: `${centerX}px ${centerY}px` }} />
          <polyline
            points={`${centerX},${lineOffset} ${centerX},338`}
            stroke="gray"
            strokeWidth="1"
            strokeDasharray="2"
            fill="none"
            style={{ transform: `rotate(${-lie}deg)`, transformOrigin: `${centerX}px ${centerY}px` }} />
          <polyline
            points={`${offsetX},${centerY} 500,${centerY}`}
            stroke="red"
            strokeWidth="3"
            fill="none" />
          {shots.map((x, i) => {
            let last = shots.length - 1;
            let opacity = 0.8 - ((i >= last - 4 ? last - i : 4) * 0.15);
            let innerFill = this.store.clubColors[x.club];

            return (<React.Fragment key={x.id}>
              <circle key={x.id + '_a'} cx={centerX + (x.shotData.clubData.horizontalImpact * factor)} cy={centerY - (x.shotData.clubData.verticalImpact * factor)} r={10} fill={innerFill} style={{ opacity: opacity }} />
              <circle key={x.id + '_b'} cx={centerX + (x.shotData.clubData.horizontalImpact * factor)} cy={centerY - (x.shotData.clubData.verticalImpact * factor)} r={14} fill="none" stroke={this.store.clubColors[x.club]} strokeWidth={3} style={{ opacity: opacity }} />
              {i >= last - 4 && <circle key={x.id + '_c'} cx={centerX + (x.shotData.clubData.horizontalImpact * factor)} cy={centerY - (x.shotData.clubData.verticalImpact * factor)} r={9} fill="none" stroke="#eee" strokeWidth={i == last ? 4 : 1.5} style={{ opacity: opacity }} />}
            </React.Fragment>)
          })}
        </g>
      </svg>
    );

    const content = <div>
      <div className="row">
        <div className="col-md-12">
          {renderStrike()}
          <If condition={shots.length > 0}>
            <Then>{() => (
              <div style={{marginTop: 10, textAlign:'center', fontWeight: 100}}>
                Lie: <strong>{shots[shots.length - 1].shotData.clubData.lie}&deg;</strong>&nbsp;
                Horizontal Impact: <strong>{shots[shots.length - 1].shotData.clubData.horizontalImpact}mm</strong>&nbsp;
                Vertical Impact: <strong>{shots[shots.length - 1].shotData.clubData.verticalImpact}mm</strong>
              </div>
            )}
            </Then>
          </If>
        </div>
      </div>
    </div>;

    return (
      <Modal size="md" fade={false} isOpen={this.store.isVisualOpen} toggle={() => this.store.toggleVisual()} >
        <ModalHeader className="modal-title">
          Shot Visual
        </ModalHeader>
        <ModalBody>{content}</ModalBody>
      </Modal>
    );
  }
}
