import { SessionShotsState } from "./SessionShotState";
import { fetchJson, toDict, orderBy } from "../Utils";
import { action, observable, computed } from "mobx";

let defaultNewSession = { name: '', isPrivate: false };

class SessionState {
  private sessionShots: { [sessionId: string]: SessionShotsState } = {};
  
  @observable
  public sessions: { [id: string] : Session } = {};
  @observable
  public isLoading: boolean = false;
  @observable
  public showCreateSession: boolean = false;
  @observable
  public editSessionId: string = '';

  @observable
  public editSessionData: { name: string, isPrivate: boolean } = defaultNewSession;

  get(sessionId: string) {
    var sessionShotState = this.sessionShots[sessionId] || (this.sessionShots[sessionId] = new SessionShotsState(sessionId));
    return sessionShotState;
  }

  @computed
  get orderedSessions() {
    return orderBy(Object.keys(this.sessions).map(x=>this.sessions[x]), x => x.startedAt, false);
  }

  @action
  async getSessions() {
    this.isLoading = true;
    this.showCreateSession = false;
    this.sessions = toDict(await fetchJson<Session[]>('/webapi/sessions'), x => x.sessionId);
    this.isLoading = false;
  }

  @action 
  editSession(sessionId: string) {
    this.editSessionId = sessionId;
    this.showCreateSession = true;
    this.editSessionData.name = this.sessions[sessionId].name;
    this.editSessionData.isPrivate = this.sessions[sessionId].isPrivate;
  }

  @action 
  async deleteSession(sessionId: string) {
    await fetchJson(`/webapi/sessions/delete`, { params: { sessionId: this.sessions[sessionId].id }, method: 'POST' });
    delete this.sessions[sessionId];
  }

  @action 
  createSession() {
    this.showCreateSession = true;
    this.editSessionId = '';
  }

  @action
  cancelCreateSession() {
    this.showCreateSession = false;
  }

  @action
  async createSessionSubmitted() {
    var query = this.editSessionId ? { sessionId: this.sessions[this.editSessionId].id } : undefined;
    var result = await fetchJson<Session>(`/webapi/sessions/create`, { params: query, method: 'POST', data: this.editSessionData });
    this.sessions[result.sessionId].name = result.name;
    this.sessions[result.sessionId].isPrivate = result.isPrivate;
    this.editSessionData = defaultNewSession;
    this.editSessionId = '';
    this.showCreateSession = false;
  }

  @action
  setNewSession<T extends keyof typeof defaultNewSession>(data: Pick<typeof defaultNewSession, T>) {
    this.editSessionData = Object.assign(this.editSessionData, data);
  }
}

export interface Session {
  id: string;
  sessionId: string;
  name: string;
  isPrivate: boolean;
  startedAt: string;
  shotCount: number;
}

export const sessionState = new SessionState();