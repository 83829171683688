import * as React from "react";
import { Point, BallFlightData, CarryDispersion } from '../Stores/SessionShotState';
import { observer } from "mobx-react";
import underscore from 'underscore';

interface BallFlightProps {
  carryDispersion: CarryDispersion;
  ballFlightData: BallFlightData[];
  displayInMetres: boolean;
  displayDispersionCircles: boolean;
  animateLast: boolean;  
  clubColors: { [id: string]: string };
  currentHoverShotId: string | null;
}

function above(list: Point[], multiplier: number, isMetres: boolean) {
  return list.map(x => (multiplier * x.x * (isMetres ? 0.9144 : 1)).toFixed(2) + ',' + (50 + (multiplier * x.y * (isMetres ? 0.9144 : 1))).toFixed(2)).join(' ');
}

function side(list: Point[], multiplier: number, isMetres: boolean) {
  return list.map(x => (multiplier * x.x * (isMetres ? 0.9144 : 1)).toFixed(2) + ',' + (90 - (multiplier * x.z * (isMetres ? 0.9144 : 1))).toFixed(2)).join(' ');
}

function point(point: number, multiplier: number, isMetres: boolean) {
  return +(point * multiplier * (isMetres ? 0.9144 : 1)).toFixed(2);
}

function ballFlight(props: BallFlightProps) {

    var isMetres = props.displayInMetres;
    var points = props.ballFlightData;
    var distanceMarkers = [50, 100, 150, 200, 250, 300, 350, 400];

    var maxCarry = underscore.max([0].concat(points.map(x => x.points.length > 0 ? x.points[x.points.length-1].x * (isMetres ? 0.9144 : 1) : 0)));
    var maxWidth = underscore.max([0].concat(points.map(x => x.points.length > 0 ? Math.abs(x.points[x.points.length-1].y * (isMetres ? 0.9144 : 1)) : 0)));

    var dispersions = props.carryDispersion;
    var multiplier1 = 3 / ((maxCarry + (0.1 * Math.max(maxCarry, 100))) / 100);
    var multiplier2 = 2 / ((maxWidth + (0.1 * maxWidth)) / 25);
    var multiplier = points.length > 0 ? Math.min(multiplier1, multiplier2) : 1;

    var symbol = isMetres ? 'm' : 'y';
    var sideDispersion = (isMetres?dispersions.x * 0.9144:dispersions.x).toFixed(1) + symbol;
    var aboveDispersion = (isMetres?dispersions.y * 0.9144:dispersions.y).toFixed(1) + symbol;

    var std1 = dispersions.dispersionsPerClub.map(x=>{
      return props.displayDispersionCircles && x.selected > 1 && <React.Fragment key={x.club}>
        <ellipse key={x.club+"_1"} cx={point(x.avgXs, multiplier, isMetres)} cy={50 + point(x.avgYs, multiplier, isMetres)} rx={x.xStdDev} ry={x.yStdDev} fill="none" stroke="#d4d4d4" strokeDasharray="2 2 2 2 0 4" />
        <ellipse key={x.club+"_2"} cx={point(x.avgXs, multiplier, isMetres)} cy={50 + point(x.avgYs, multiplier, isMetres)} rx={x.xStdDev} ry={x.yStdDev} fill="none" stroke={props.clubColors[x.club] || "#d4d4d4"} strokeDasharray="0 8 2 2" />
      </React.Fragment>
    }).filter(x => x);

    var std2 = dispersions.dispersionsPerClub.map(x=>{
      return props.displayDispersionCircles && x.selected > 1 && <ellipse key={x.club} cx={point(x.avgXs, multiplier, isMetres)} cy={50 + point(x.avgYs, multiplier, isMetres)} rx={x.xStdDev*2} ry={x.yStdDev*2} fill="none" stroke="#828282" strokeDasharray="2" strokeWidth="0.5" />
    }).filter(x => x);

    return (
      <div className="tracer-container">
        <div style={{ display: 'inline-block', width: '50%', padding: '0 2%' }}>
          <h6>From above <span style={{ fontWeight: 'normal', float: 'right' }}>{aboveDispersion}</span></h6>
          <svg width="100%" viewBox={`0 0 300 100`}>
            {distanceMarkers.map(x => 
              <circle key={`dist_${x}`} cx="0" cy="50" r={point(x, multiplier, false)} fill="none" stroke="#2a2b2d" />
            )}
            <polyline
              points={`0,50 300,50`}
              stroke="gray"
              strokeWidth="1"
              fill="none" />
            {std2}
            {points.map((x, i) =>
                <polyline
                  className={!props.animateLast || (props.animateLast && i == points.length-1) ? 'line' : ''}
                  key={x.shotId}
                  points={above(x.points, multiplier, isMetres)}
                  stroke={props.clubColors[x.club]}
                  strokeWidth="1"
                  fill="none" />
            )}
            {std1}
            {points.map((x, i) =>
              <circle key={x.shotId} cx={point(x.points[x.points.length-1].x, multiplier, isMetres)} cy={50 + point(x.points[x.points.length-1].y, multiplier, isMetres)} r={props.currentHoverShotId === x.shotId || (props.currentHoverShotId == null && i == points.length-1) ? 3 : 1.5} fill="#c7c7c7"/>
            )}
           <text x="300" y="52" fill="#c7c7c7" textAnchor="end" dominantBaseline="hanging" style={{ fontSize: '8px' }}>{(300/multiplier).toFixed()+symbol}</text>
          </svg>
        </div>
        <div style={{ display: 'inline-block', width: '50%', padding: '0 2%' }}>
          <h6>From the side <span style={{ fontWeight: 'normal', float: 'right' }}>{sideDispersion}</span></h6>
          <svg width="100%" viewBox={`0 0 300 100`}>
            <polyline points={`0, ${90 - point(25, multiplier, false)} 300,${90 - point(25, multiplier, false)}`} fill="none" stroke="#1d1d23" />
            <polyline points={`0, ${90 - point(50, multiplier, false)} 300,${90 - point(50, multiplier, false)}`} fill="none" stroke="#1d1d23" />
            <polyline points={`0, ${90 - point(75, multiplier, false)} 300,${90 - point(75, multiplier, false)}`} fill="none" stroke="#1d1d23" />
            {distanceMarkers.map(x => 
              <polyline key={`dist_${x}`} points={`${point(x, multiplier, false)},0 ${point(x, multiplier, false)},90`} fill="none" stroke="#2a2b2d" />
            )}
            <polyline
              points={`0,90 300,90 `}
              stroke="gray"
              strokeWidth="1"
              fill="none" />
            {points.map((x, i) =>
              <polyline
                className={!props.animateLast || (props.animateLast && i == points.length-1) ? 'line' : ''}
                key={x.shotId}
                points={side(x.points, multiplier, isMetres)}
                stroke={props.clubColors[x.club]}
                strokeWidth="1"
                fill="none" />
            )}
            {points.map((x, i) =>
              <circle key={x.shotId} cx={point(x.points[x.points.length-1].x, multiplier, isMetres)} cy={90 + point(x.points[x.points.length-1].z, multiplier, isMetres)} r={props.currentHoverShotId === x.shotId || (props.currentHoverShotId == null && i == points.length-1) ? 3 : 1.5} fill="#c7c7c7"/>
            )}
            <text x="300" y="92" fill="#c7c7c7" textAnchor="end" dominantBaseline="hanging" style={{ fontSize: '8px' }}>{(300/multiplier).toFixed()+symbol}</text>
          </svg>
        </div>
      </div>
    );
}


export const BallFlight = observer(ballFlight);