import { observable, action, computed } from "mobx";
import { fetchJson } from "../Utils";

interface VersionInfo {
  latestVersion: string
}

class HomeState {
  @observable
  public sessionId: string = '';

  @observable
  public versionId: string = '';

  @observable
  public isLoadingVersionId: boolean = false;

  @action
  updateSessionId(sessionId: string) {
    this.sessionId = sessionId;
  }

  @action
  async loadVersion() {
    this.isLoadingVersionId = true;
    this.versionId = (await fetchJson<VersionInfo>('/webapi/version')).latestVersion;
    this.isLoadingVersionId = false;
  }
}

export const homeState = new HomeState();