import { observable, action, computed } from "mobx";
import { AuthDetails } from "../App";
import { routeState, Route } from "./RouteState";
import { fetchJson } from "../Utils";

class GlobalState {
  @observable
  public isTracerActive: boolean = true;
  
  @observable
  public auth: AuthDetails = {
    isAuthenticated: false,
    user: {
      username: '',
      apiKey: '',
      isMetres: false,
      isPremium: false
    }
  };

  @observable
  public windowDimensions: WindowDimensions = { width: 0, height: 0 }

  @observable
  public isCollapsedManu: boolean = true;

  @computed
  public get isSmallMenu(): boolean {
    return !this.isSmallScreen && this.isCollapsedManu && (this.auth.isAuthenticated || routeState.route == Route.sessionShots);
  }

  @action
  async loadAuth() {
    this.auth = await fetchJson<AuthDetails>('/auth-details');
  }

  @action
  toggleCollapsedMenu() {
    this.isCollapsedManu = !this.isCollapsedManu;
  }

  @action
  setAuth(auth: AuthDetails) {
    this.auth = auth;
  }

  @action
  toggleTracer(override?: boolean) {
    this.isTracerActive = override === undefined ? !this.isTracerActive : override;
  }

  @computed
  get showBottomBar() {
    return routeState.route === 'sessionShots' && this.isTracerActive;
  }

  @action
  setWindowDimensions(windowDimensions: WindowDimensions) {
    this.windowDimensions = windowDimensions;
  }

  @computed 
  get isSmallScreen() {
    return this.windowDimensions.width <= 1024;
  }
}

interface WindowDimensions { 
  width: number; 
  height: number; 
}

export const globalState = new GlobalState();