import * as React from 'react';
import { sessionState } from '../Stores/SessionState';
import { observer } from 'mobx-react';
import { SessionShotsState, AutoSelectOnShot, SpinNumbers } from '../Stores/SessionShotState';
import { Modal, ModalHeader, ModalBody } from 'reactstrap';

interface Props {
  sessionId: string
}

@observer
export class ShotSettings extends React.Component<Props> {
  store: SessionShotsState;

  constructor(props: Props) {
    super(props);
    this.store = sessionState.get(props.sessionId)
  }

  render() {
    const form = <form onSubmit={(e) => { e.preventDefault(); }}>
      <div className="form-group row">
        <div className="col-sm-10">Display in Metres?</div>
        <div className="col-sm-2">
          <div className="form-check">
            <input className="form-check-input" type="checkbox" id="units" checked={this.store.displayInMetres} onChange={e => this.store.setSetting({ isMetres: e.target.checked })} />
            <label className="form-check-label" htmlFor="units"></label>
          </div>
        </div>
      </div>
      <div className="form-group row">
        <label className="col-sm-8" htmlFor="autoSelect">Auto select on Shot?</label>
        <div className="col-sm-4">
          <select id="inputState" className="form-control" value={this.store.settings.autoSelectOnShot} onChange={e => this.store.setSetting({ autoSelectOnShot: e.target.value as AutoSelectOnShot })}>
            <option value={AutoSelectOnShot.No}>No</option>
            <option value={AutoSelectOnShot.Yes}>Yes</option>
            <option value={AutoSelectOnShot.LastOnly}>Last Only</option>
            <option value={AutoSelectOnShot.Last5}>Last 5</option>
            <option value={AutoSelectOnShot.LastClub}>Last Club</option>
          </select>
        </div>
      </div>
      <div className="form-group row">
        <div className="col-sm-10">Show Club Data on First shot?</div>
        <div className="col-sm-2">
          <div className="form-check">
            <input className="form-check-input" type="checkbox" id="units" checked={this.store.settings.showClubDataOnFirst} onChange={e => this.store.setSetting({ showClubDataOnFirst: e.target.checked })} />
            <label className="form-check-label" htmlFor="units"></label>
          </div>
        </div>
      </div>
      <div className="form-group row">
        <div className="col-sm-10">Show Dispersion Circles?</div>
        <div className="col-sm-2">
          <div className="form-check">
            <input className="form-check-input" type="checkbox" id="units" checked={this.store.settings.showDispersionCircles} onChange={e => this.store.setSetting({ showDispersionCircles: e.target.checked })} />
            <label className="form-check-label" htmlFor="units"></label>
          </div>
        </div>
      </div>
      {this.store.settings.showDispersionCircles &&
        <div className="form-group row">
          <div className="col-sm-10">Show Dispersion Circles By Club?</div>
          <div className="col-sm-2">
            <div className="form-check">
              <input className="form-check-input" type="checkbox" id="units" checked={this.store.settings.showDispersionCirclesByClub} onChange={e => this.store.setSetting({ showDispersionCirclesByClub: e.target.checked })} />
              <label className="form-check-label" htmlFor="units"></label>
            </div>
          </div>
        </div>}
      <div className="form-group row">
        <div className="col-sm-6">Spin Numbers</div>
        <div className="col-sm-6">
          <select id="inputState" className="form-control" value={this.store.settings.spinNumbers} onChange={e => this.store.setSetting({ spinNumbers: +e.target.value as SpinNumbers })}>
            <option value={SpinNumbers.SideBack}>Back Spin / Side Spin</option>
            <option value={SpinNumbers.AxisTotal}>Total Spin / Spin Axis</option>
          </select>
        </div>
      </div>
    </form>;

    return (
      <Modal className="" isOpen={this.store.isSettingsOpen} toggle={() => this.store.toggleSettings()} >
        <ModalHeader className="modal-title">
          Settings
        </ModalHeader>
        <ModalBody>{form}</ModalBody>
      </Modal>
    );
  }
}
