import * as React from 'react';
import { observer } from 'mobx-react';
import { Link } from './Link';

@observer
export class Help extends React.Component {
  constructor(props: any) {
    super(props);
  }

  render() {
    return (
      <div>
        <div className="card">
          <div className="card-header">
            <h3 className="card-title">Help</h3>
          </div>
          <div className="card-body">
            Here are all the answers to all your swing problems... or its just some information to help you start figuring out how many problems you do have.
          </div>
        </div>
        <div className="row">
          <div className="col-md-6">
            <div className="card">
              <div className="card-header">
                <h3 className="card-title">Schogolf Client</h3>
              </div>
              <div className="card-body">
                <h4 className="card-title">Getting started</h4>
                <ol>
                  <li>Download the client from <a target="_blank" href="/client_files/Schogolf.zip">here</a></li>
                  <li>Unzip it to a location of your choice</li>
                  <li>Run the application</li>
                  <li>Open the settings tab</li>
                  <li>Go and grab your API key from the <Link href="/profile">Profile</Link> menu</li>
                  <li>Paste it into the API key on the settings tab</li>
                  <li>Select OGT interface (for use with JNPG) or COM port for direct integration</li>
                  <li>Select metres or yards</li>
                  <li>Go back to the main tab and select to create a session</li>
                  <li>Enter a name for the session and click Create</li>
                  <li>If you have chosen OGT interface its ready and waiting for connections, (Note: Windows may ask you to allow connections. If you are on the same network private is all you need) otherwise click Connect. Next time you start Schogolf OGT will wait for connections on startup if chosen as the connection type</li>
                  <li>If you are using the OGT interface integration then in the interface, goto the settings tab and click Connect</li>
                  <li>The Schogolf application should now say connected</li>
                  <li>Start Swinging</li>
                  <li>To open the overlay click Open Overlay</li>
                </ol>
                <h4 className="card-title">Connecting to an existing session</h4>
                <ol>
                  <li>Once you have created a session you may want to add more shots, so click Existing Session</li>
                  <li>Enter the Session Id in if you know it or click Get Latest</li>
                  <li>The session can then be open by clicking Open Session</li>
                  <li>Now swing harder</li>
                </ol>
                <h4 className="card-title">Overlay</h4>
                <ul>
                  <li>Choose the club to be recorded for the current shot. Note: free text is supported</li>
                  <li>If connecting via OGT interface then selecting the OGT Club in the club dropdown will record the JNPG club</li>
                  <li>Double clicking the top bar will dock the window to the left at the current height</li>
                </ul>
                <h4 className="card-title">Settings</h4>
                <ul>
                  <li>COM port dropdown is not required when using OGT Interface integration</li>
                  <li>Choose between metres and yards for the overlay display</li>
                </ul>
                <h4 className="card-title">USB Connection</h4>
                <ul>
                  <li>Firstly an open driver needs to be installed for the GC2</li>
                  <li>Download Zadig here: <a target="_blank" href="https://zadig.akeo.ie/">https://zadig.akeo.ie/</a></li>
                  <li>Open Zadig and find your GC2. You might have to select Options -> List All Devices. It can be called Unknown Device #1 or NGLM MSC Interface Device. It should look like <a target="_blank" href="/zadig.png">this</a></li>
                  <li>Select WinUSB and install or replace driver</li>
                  <li>Select USB connection in settings of Schogolf client</li>
                </ul>
                <h4 className="card-title">Alignment</h4>
                <ul>
                  <li>Select USB as the connection type</li>
                  <li>Click Connect</li>
                  <li>In the settings tab click Open Alignment</li>
                  <li>Place one ball in each of the two boxes so that the GC2 says there are multiple balls and when the number is 0 you are aligned</li>
                </ul>
              </div>
            </div>
          </div>
          <div className="col-md-6">
            <div className="card">
              <div className="card-header">
                <h3 className="card-title">The Website</h3>
              </div>
              <div className="card-body">
                <h4 className="card-title">Signing In</h4>
                <ul>
                  <li>You have! Well done</li>
                  <li>Only your email address and discord username are stored</li>
                  <li>To logout click Signout in the menu</li>
                </ul>
                <h4 className="card-title">Sessions (WIP)</h4>
                <ul>
                  <li>Sessions can be also created in the web interface by clicking on the Sessions menu then Create Session</li>
                  <li>Sessions are displayed in reverse chronological order</li>
                </ul>
                <h4 className="card-title">Shots</h4>
                <ul>
                  <li>By default shots are displayed in reverse chronological order with a single summary at the bottom</li>
                  <li>There are currently 2 grouping modes:
                    <br /><strong>Grouped:</strong> This keeps the shots in order and groups shots of the same clubs together with their summary
                    <br /><strong>Grouped By Club:</strong> This groups all shots by the same club
                  </li>
                  <li>Shots can be selected by clicking the checkboxes. This will dim the other shots in the group and they will be excluded from the summaries</li>
                  <li>Groups can also be selected by clicking the bar to the left of the checkboxes</li>
                  <li>All data comes directly from the GC2 except for Offline, Height and Carry which is calculated. Note: Carry is directly from the GC2</li>
                  <li>The club can be edited by clicking on it</li>
                </ul>
                <h4 className="card-title">Tracer</h4>
                <ul>
                  <li>The tracer is switched on by default and can be switched off by clicking Tracer</li>
                  <li>The shots are rendered when they are selected</li>
                  <li>Colours are allocated based on the different clubs</li>
                  <li>Dispersions are shown in the top right corner of each visualisation. Note: Carry dispersion is based off the Schogolf algorithm</li>
                </ul>
                <h4 className="card-title">Live</h4>
                <ul>
                  <li>If the <span className="badge badge-success">Live</span> badge is shown then all shots will be updated without refreshing</li>
                  <li>Currently by default, all new shots are automatically selected. (will be settings around this eventually)</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
